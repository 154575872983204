import React, { useEffect } from "react";
import DataAnalysis from "./components/DataAnalysis";
import GameList from "./components/GameList";
import Gameplay from "./components/Gameplay";
import Login from "./components/Login";
import Introduction from "./components/Introduction";
import Landing from "./components/Landing";
import CustomUpload from "./components/CustomUpload/LLMS";
import { useSelector } from "react-redux";
import { useLazyGetUserDetailsQuery } from "./store/services/userLogin"
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import "./App.css";

function App() {
  const jwt = useSelector((state) => state?.user?.jwt);
  const [getUserDetailsQuery] = useLazyGetUserDetailsQuery();

  useEffect(() => {
    if (jwt) {
      getUserDetailsQuery({
        token: jwt
      })
    }
  }, [jwt])

  return (
    <div className="app-wrapper" style={{ width: '100%', height: "100%", backgroundColor: "#010C12" }}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={jwt ? <Navigate to="/gamelist" /> : <Login />} />
            <Route path="/" element={<Landing />} />
            <Route path="/introduction" element={<Introduction />} />
            <Route path="/gamelist" element={<GameList />} />
            <Route path="/gamified-data-analytics/:problemId" element={<Gameplay />} />
            <Route path="/analysis" element={<DataAnalysis />} />
            <Route path="/custom-upload" element={<CustomUpload />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;

