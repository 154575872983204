import React from 'react';

const ExpandIcon = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="4.25" fill="url(#paint0_linear_319_6328)" fillOpacity="0.16" />
    <rect x="0.5" y="0.5" width="33" height="33" rx="3.75" stroke="white" strokeOpacity="0.08" />
    <path d="M6.9999 25.7959L12.653 20.1428L8.83656 20.1428L8.83656 18.4784L13.8297 18.4784C14.7477 18.4784 15.4941 19.2249 15.4941 20.1428L15.4941 25.136L13.8297 25.136L13.8297 21.3195L8.17663 26.9727L6.9999 25.7959Z" fill="white" />
    <path d="M24.6575 15.4941L19.6643 15.4941C18.7464 15.4941 17.9999 14.7477 17.9999 13.8297L17.9999 8.83656L19.6643 8.83656L19.6643 12.653L25.3174 6.9999L26.4941 8.17663L20.841 13.8297L24.6575 13.8297L24.6575 15.4941Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_319_6328" x1="-9.65909" y1="-28.8679" x2="50.4408" y2="2.52376" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D7EDED" />
        <stop offset="1" stopColor="#CCEBEB" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default ExpandIcon;
