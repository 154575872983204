import React, { useState } from 'react'
import { motion } from 'framer-motion';
import DataAnalysisGif from "../../assets/animations/dataAnalysis.gif"
import "./styles.css"

const TreatmentModal = ({
    open,
    setShowTreatmentModal,
    setShowMissingDataModal
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    let slideShowContent = [
        {
            text: "Sometimes, datasets hold back secrets with blank or undefined values. These missing entries can disrupt analysis and skew results. <br />  Your job here is to decide how to handle them—should they be ignored, filled with averages, or replaced using smarter techniques? Let’s fill those gaps and make the data whole again!",
            img: DataAnalysisGif
        },
        {
            text: `<p style="font-weight:600; margin-right:15px">Mean Imputation</p> <br /> Replace missing values with the average of all the values in the column. It works best for numeric data when the values are evenly distributed (no major outliers).
`,
            img: DataAnalysisGif
        },
        {
            text: `<p style="font-weight:600; margin-right:15px">Median Imputation</p>  <br /> Replace missing values with the middle value when the data is sorted. It works well for numeric data with outliers, as it avoids distortion by extreme values.`,
            img: DataAnalysisGif
        },
        {
            text: `<p style="font-weight:600; margin-right:15px">Mode Imputation</p> <br /> Replace missing values with the most frequently occurring value in the column. It is ideal for categorical data (e.g., gender, city) where the most common value is meaningful.`,
            img: DataAnalysisGif
        }
    ];

    const handleClose = () => {
        setShowTreatmentModal(false);
        setTimeout(() => {
            setShowMissingDataModal(true)
        }, 500)
        if (isChecked) {
            localStorage.setItem('dont-show-missing-value', 'true');
            setIsChecked(false);
        }
    }


    const handleCheckboxChange = () => {
        setIsChecked((prev) => !prev);
    }

    return open &&
        <div
            className="modal-overlay"
            onClick={() => {
                handleClose();
            }}
        >
            <motion.div
                className="modal-content"
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '100%', opacity: 0 }}
                transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                    duration: 0.5,
                }}
                onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
            >
                <h2 style={{ marginBottom: "30px" }}>Missing Value Treatment Through Imputation</h2>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <p
                        style={{ width: "50%", fontSize: "18px", marginRight: "10px" }}
                        dangerouslySetInnerHTML={{ __html: slideShowContent?.[activeIndex]?.text }}
                    ></p>

                    <img className='csv-gif' alt={"csv"} src={slideShowContent?.[activeIndex]?.img} style={{
                        width: "50%",
                        borderRadius: "10px"
                    }} />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-end", // Ensure items align to the bottom,
                        marginTop: "15px"
                    }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",// Space between checkbox and label
                        }}
                    >
                        <input
                            className='dont-show-again-checkbox'
                            type="checkbox"
                            id="dontShowAgain"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <label
                            htmlFor="dontShowAgain"
                            style={{
                                color: "#727070",
                                fontSize: "16px",
                                cursor: "pointer",
                            }}
                        >
                            {"Don't"} show this again
                        </label>
                    </div>
                    <button
                        className='close-btn'
                        onClick={() => {
                            if (activeIndex === 3) {
                                handleClose();
                            } else {
                                setActiveIndex(activeIndex + 1)
                            }

                        }}
                    >
                        {activeIndex === 3 ? "Start" : "Next"}
                    </button>
                </div>
            </motion.div>
        </div>
}

export default TreatmentModal;