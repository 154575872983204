import React from 'react';
import { motion } from "framer-motion";

const DeleteDuplicateModal = ({
    onClickHandler,
    showDeleteToast
}) => {
    return (
        showDeleteToast && <motion.div
            className="duplicate-dataset-toast"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 50, damping: 15 }}
        >
            Delete duplicate data
            <button className="action-btn" onClick={() => {
                onClickHandler();
            }}>
                Delete
            </button>
        </motion.div>

    )
}

export default DeleteDuplicateModal