import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import DataAnalysisGif from '../../assets/animations/data-AnalysisNew.gif';

import "./styles.css";

const AnalysisModal = ({ open, handleClose }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(open);

    useEffect(() => {
        const dontShow = localStorage.getItem('dont-show-analysis-modal') === 'true';
        if (dontShow) {
            setIsModalOpen(false);
        } else {
            setIsModalOpen(open);
        }
    }, [open]);

    const slideShowContent = [
        {
            text: "This is where the magic happens! Your clean and transformed dataset is ready for analysis.",
            img: DataAnalysisGif,
        },
        {
            text: "In this module, you’ll dig deep into the data to identify patterns, trends, and insights. It’s like solving a mystery—you’ll uncover the story hidden in the data.",
            img: DataAnalysisGif,
        },
        {
            text: "Whether it’s identifying declining trends, demographic patterns, or pricing effects, your goal is to extract meaningful information.",
            img: DataAnalysisGif,
        },
        {
            text: "To begin with, try finding out the count of monthly subscribers for each card type.",
            img: DataAnalysisGif,
        },
    ];

    const handleModalClose = () => {
        setIsModalOpen(false);
        handleClose();
        if (isChecked) {
            localStorage.setItem('dont-show-analysis-modal', 'true');
        }
    };

    const handleCheckboxChange = () => {
        setIsChecked((prev) => !prev);
    };

    return (
        isModalOpen && (
            <div
                className="modal-overlay"
                onClick={() => {
                    handleModalClose();
                }}
            >
                <motion.div
                    className="modal-content"
                    initial={{ y: '100%', opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: '100%', opacity: 0 }}
                    transition={{
                        type: 'spring',
                        stiffness: 300,
                        damping: 30,
                        duration: 0.5,
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
                >
                    <h2 style={{ marginBottom: "30px" }}>Data Analysis</h2>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <p style={{ width: "50%", fontSize: "18px", marginRight: "10px" }}>
                            {slideShowContent?.[activeIndex]?.text}
                        </p>
                        <img
                            className="csv-gif"
                            alt={"csv"}
                            src={slideShowContent?.[activeIndex]?.img}
                            style={{
                                width: "50%",
                                borderRadius: "10px",
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            marginTop: "15px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",// Space between checkbox and label
                            }}
                        >
                            <input
                                className='dont-show-again-checkbox'
                                type="checkbox"
                                id="dontShowAgain"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label
                                htmlFor="dontShowAgain"
                                style={{
                                    color: "#727070",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                }}
                            >
                                {"Don't"} show this again
                            </label>
                        </div>
                        <button
                            className="close-btn"
                            onClick={() => {
                                if (activeIndex === slideShowContent.length - 1) {
                                    handleModalClose();
                                } else {
                                    setActiveIndex(activeIndex + 1);
                                }
                            }}
                        >
                            {activeIndex === slideShowContent.length - 1 ? "Start" : "Next"}
                        </button>
                    </div>
                </motion.div>
            </div>
        )
    );
};

export default AnalysisModal;
