import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { dataTypeIcons } from '../../constants'

function DataTypeIcon({ type }) {
  const DataTypeIcon = dataTypeIcons[type]
  return (
    <span>
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Tooltip id={`tooltip-top`}>
            Accepts {type}s
          </Tooltip>
        }
      >
        <DataTypeIcon style={{
          marginRight: "8px",
          color: "#FFF",
          textAlign: "center",
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "19.048px",
          letterSpacing: "-0.397px",

        }} />
      </OverlayTrigger>
    </span>
  )
}

export default DataTypeIcon
