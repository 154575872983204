import React from "react";
import { Card, Box, Typography } from "@mui/material";
import Icon from "../assets/img/icon.svg";

const CustomCard = ({ firstImage, cardText, sx, ...otherProps }) => {
  return (
    <Card
      sx={{
        height: "230px",
        borderRadius: "12px",
        borderTop: "1px solid rgba(255, 255, 255, 0.08)",
        background:
          "linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0) 100%)",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        cursor: "pointer",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",

        ...sx,
      }}
      {...otherProps}
    >
      <Box
        sx={{
          width: { xs: "40px", sm: "50.703px" },
          height: "46px",
          marginBottom: "15px",
        }}
      >
        <Box
          component="img"
          src={firstImage}
          alt="First"
          sx={{
            width: "35.703px",
            height: "46px",
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          color: "#fff",
          textAlign: "left",
        }}
      >
        {cardText}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          right: "20px",
          bottom: "20px",
        }}
      >
        <Box
          component="img"
          src={Icon}
          alt="Icon"
          sx={{
            width: "30px",
            height: "14px",
          }}
        />
      </Box>
    </Card>
  );
};

export default CustomCard;
