const ProblemList = {
    "Subscription Drop Analysis": {
        welcomeTitle: "Subscription Drop Analysis",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "Let’s do subscription data analysis and figure out if other cards are gaining popularity, customer preferences are shifting, or the Platinum card’s fee is the culprit.Time to uncover the story behind the numbers!",
        description: "Identify the potential causes of the decline in the Platinum Rewards Card subscriptions by analyzing a one-year dataset containing information on monthly subscriptions across multiple credit card categories, subscription fees, and customer demographics.",
        beforeStarting: "Alright, let’s put your visualization skills to the test! Let’s start with creating a column chart showing the number of users",
        beforeStarting2: "This will help us identify monthly trends and figure out what might be going wrong with Platinum cards. Ready? Let’s dive in!",
        onSuccess: "Great job! Look at those bars—do you spot any dips in Platinum cards? This graph holds some important clues.",
        quizQuestion: "Based on the trends you observed, Explain Why the Platinum Rewards Card subscriptions are dropping. What are the most likely factors causing this shift?",
        options: ["There is reduction in Basic Card Users subscriptions in last two months",
            "Dip in the Average income is observed in the last quarter",
            "Subscription fee is on higher side over the sustained period in last 4 months",
            "Older age people dropped subscription in last few months"
        ],
        correctAnswer: [
            "Subscription fee is on higher side over the sustained period in last 4 months",
        ],
        correctGuessMessage: "That’s it! The higher subscription fee over the last four months seems to be driving users away. Great job piecing this together!",
        defaultBarIndex: 2,
        chartingSequence: {
            "Bars": {
                columns: ["Month"],
                order: 1,
                successMessage: "Drag ‘Month’ and drop it in the 'Bars' section."
            },
            "Series": {
                columns: ["Card Category"],
                order: 2,
                successMessage: "Drag ‘Card Category’ and drop it in the 'Series' section."
            }
        },

    },
    "Ecommerce Repeat Purchase Analysis": {
        welcomeTitle: "ECommerce Repeat Purchase Analysis",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "Let’s unravel the mystery of declining repeat purchases! Could it be dissatisfaction with certain product categories, delivery delays, or even changing customer preferences? Join me as we dive into the data and uncover the factors affecting customer loyalty!",
        description: "Identify the potential causes behind a decline in repeat customer purchases by analyzing a one-year dataset that includes customer orders, product categories, satisfaction scores, and delivery times. Discover actionable insights to drive customer loyalty and retention.",
        beforeStarting: "Let’s start by exploring customer satisfaction across different product categories. Do certain categories bring smiles, or are there hidden frowns?",
        beforeStarting2: "Do certain categories bring smiles, or are there hidden frowns? Let’s find out!",
        onSuccess: "Fantastic work! Do you see any categories that stand out, positively or negatively?",
        quizQuestion: "Based on the insights provided, which actions should the company prioritize to address the decline in repeat purchases?",
        options: ["Focus on improving customer satisfaction scores and delivery times for electronics and home appliances",
            "Offer discounts across all categories regardless of satisfaction levels.",
            "Ignore low customer satisfaction scores and focus solely on faster delivery times.",
            "Reduce the number of products in the electronics category."
        ],
        correctAnswer: ["Focus on improving customer satisfaction scores and delivery times for electronics and home appliances"],
        correctGuessMessage: "That’s absolutely right! Targeting underperforming categories and optimizing delivery times is the key to boosting repeat purchases. Well done!",
        defaultBarIndex: 2,
        chartingSequence: {
            "Bars": {
                columns: ["Product Category"],
                order: 1,
                successMessage: "Drag ‘Product Category’ and drop it in the 'Bars' section."
            },
            "Size": {
                columns: ["Customer Satisfaction Score"],
                aggregate: ["Average"],
                order: 2,
                successMessage: "Drag ‘Customer Satisfaction Score’ and drop it in the 'Size' section and set it to 'Average'."
            }
        },
    },
    "Healthcare Patient No-Shows Analysis": {
        welcomeTitle: "Healthcare Patient No-Shows Analysis",
        welcomeSecondaryTitle: "Problem Statement",
        "welcomeMessage": "Let’s understand the reasons behind missed medical appointments! Could it be the lack of reminders, long distances to the clinic, or perhaps age-related patterns? Join me as we dive into the data and uncover the factors affecting patient attendance!",
        "description": "Identify the potential causes of missed appointments by analyzing a one-year dataset that includes patient ages, distances to the clinic, reminder statuses, and no-show records. Discover actionable insights to improve patient attendance and engagement.",
        "beforeStarting": "Let’s start by exploring the no-show rates across different patient age groups. Are younger patients or those farther from the clinic more likely to miss their appointments?",
        "beforeStarting2": "Are younger patients or those farther from the clinic more likely to miss their appointments? Let’s find out!",
        "onSuccess": "Fantastic work! Do you notice any trends in no-show rates based on age or distance?",
        "quizQuestion": "Based on the insights provided, which actions should the clinic prioritize to address high no-show rates?",
        "options": [
            "Send reminders to younger patients (aged 18-30) and those farther than 20 km from the clinic.",
            "Focus solely on younger patients regardless of their distance.",
            "Ignore distance and age factors and increase appointment slots.",
            "Offer incentives for attending appointments regardless of reminders."
        ],
        "correctAnswer": "Send reminders to younger patients (aged 18-30) and those farther than 20 km from the clinic.",
        "correctGuessMessage": "That’s absolutely right! Focusing on targeted reminders for key groups is the key to improving attendance rates. Well done!",
        defaultBarIndex: 2,
        chartingSequence: {
            "Bars": {
                columns: ["Age"],
                order: 1,
                successMessage: "Drag ‘Age’ and drop it in the 'Bars' section."
            },
            "Size": {
                columns: ["No-Show"],
                aggregate: ["Sum"],
                order: 2,
                successMessage: "Great! Now drag ‘No-Show’ and drop it in the 'Size' section and set it to 'Sum'."
            }
        },
    },
    "AI Age Estimation Error Analysis": {
        tableHeaders: [
            { Header: "User ID", accessor: "User ID", type: "string" },
            { Header: "Actual Age", accessor: "Actual Age", type: "number" },
            { Header: "Predicted Age", accessor: "Predicted Age", type: "number" },
            { Header: "Image Quality Score", accessor: "Image Quality Score", type: "number" },
            { Header: "Lighting Condition", accessor: "Lighting Condition", type: "string" },
            { Header: "Prediction Error", accessor: "Prediction Error", type: "number" }
        ],
        welcomeTitle: "AI Age Estimation Error Analysis",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "Let’s explore why the AI-powered age estimation tool struggles with accuracy in certain cases! Is it the image quality, lighting conditions, or perhaps biases in the model? Join me as we dive into the data to improve AI performance!",
        description: "Analyze customer interactions with an AI-powered age estimation tool to uncover factors contributing to prediction errors. Examine the impact of user demographics, image quality, and lighting conditions on accuracy to identify actionable improvements.",
        beforeStarting: "Let’s start by examining how image quality impacts prediction accuracy. Does a clearer image result in better predictions?",
        beforeStarting2: "Could lighting conditions also be a key factor? Let’s dig deeper!",
        onSuccess: "Great job! Do you notice a trend in how prediction errors change with image quality scores?",
        quizQuestion: "Based on the analysis, which factors should the AI tool prioritize for improvement to reduce prediction errors?",
        options: [
            "Enhancing model performance for dim lighting and poor image quality.",
            "Focusing only on users aged 20-30 for better training.",
            "Improving the speed of prediction output without considering quality.",
            "Reducing the dataset size to improve model efficiency."
        ],
        correctAnswer: "Enhancing model performance for dim lighting and poor image quality.",
        correctGuessMessage: "That’s absolutely right! Addressing dim lighting and poor-quality images is key to improving accuracy. Well done!",
        defaultBarIndex: 7,
        chartingSequence: {
            "X Axis": {
                columns: ["Image_Quality_Score"],
                order: 1,
                successMessage: "Drag ‘Image_Quality_Score’ and drop it in the 'X-axis' section."
            },
            "Y Axis": {
                columns: ["Prediction_Error"],
                order: 2,
                successMessage: "Great! Now drag ‘Prediction_Error’ and drop it in the 'Y-axis' Series."
            }
        },
    },
    "Understanding Decline in Data Science Enrollment": {
        welcomeTitle: "Understanding Decline in Data Science Enrollment",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "Let’s uncover the reasons behind the drop in Data Science course enrollments! Could it be marketing gaps, demographic shifts, or feedback issues? Join me as we analyze the data to find actionable solutions!",
        description: "Analyze enrollment data for a Data Science course to identify factors contributing to declining enrollment over the past two semesters. Examine demographic trends, marketing effectiveness, and feedback to recommend solutions for improving enrollment.",
        beforeStarting: "Let’s start by examining enrollment trends across different age groups over time. Are there any noticeable patterns or declines?",
        beforeStarting2: "Could certain marketing strategies or feedback scores be influencing these trends? Let’s investigate further!",
        onSuccess: "Excellent work! Do you see any age group or time period that stands out with significant changes?",
        quizQuestion: "Based on the insights provided, which actions should the institute prioritize to address the decline in enrollments?",
        options: [
            "Improve beginner-friendly content for students without programming experience.",
            "Expand referral programs targeting younger age groups (18–22).",
            "Enhance the relevance of the course for the 23–27 age group through tailored content.",
            "Reduce the focus on ad campaigns and allocate more resources to other courses."
        ],
        correctAnswer: [
            "Improve beginner-friendly content for students without programming experience.",
            "Enhance the relevance of the course for the 23–27 age group through tailored content."
        ],
        correctGuessMessage: "That’s absolutely right! Tailoring content for specific demographics and improving beginner support are key steps to boosting enrollments. Well done!",
        defaultBarIndex: 2,
        chartingSequence: {
            "Bars": {
                columns: ["Course Name"],
                order: 1,
                successMessage: "Drag ‘Course Name’ and drop it in the 'Bars' section."
            },
            "Size": {
                columns: ["Feedback Score"],
                aggregate: ["Average"],
                order: 2,
                successMessage: "Great! Now drag ‘Feedback Score’ and drop it in the 'Size' section and set it to 'Average'."
            }
        },
    },
    "Insurance Claims Analysis": {
        welcomeTitle: "Insurance Claims Analysis",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "Let’s delve into the world of insurance claims! Could policy type, claim amount, or age group be influencing rejection rates and delays? Join me as we uncover actionable insights to improve claim approval processes!",
        description: "Analyze an insurance claims dataset to identify factors contributing to high rejection rates and delays in claim approvals. Explore trends across policy types, claim amounts, and customer age groups to recommend improvements.",
        beforeStarting: "Let’s start by exploring rejection rates for different insurance policy types. Are Health Insurance claims facing higher scrutiny?",
        beforeStarting2: "Could claim amounts or age groups also play a role in rejections? Let’s find out!",
        onSuccess: "Great work! Do you notice any policy types with significantly higher rejection rates?",
        quizQuestion: "Based on the insights provided, which actions should the insurance company prioritize to reduce claim rejection rates and delays?",
        options: [
            "Streamline documentation requirements for younger customers (18–25) to reduce delays.",
            "Review Health Insurance claims policies to address higher rejection rates.",
            "Introduce specialized processes for high-value claims to ensure faster approvals.",
            "Focus solely on reducing rejection rates for Life Insurance policies."
        ],
        correctAnswer: [
            "Streamline documentation requirements for younger customers (18–25) to reduce delays.",
            "Review Health Insurance claims policies to address higher rejection rates.",
            "Introduce specialized processes for high-value claims to ensure faster approvals."
        ],
        correctGuessMessage: "That’s absolutely right! Addressing age-specific delays, policy-specific rejections, and high-value claim processes will greatly improve the claim approval system. Well done!",
        defaultBarIndex: 2,
        chartingSequence: {
            "Bars": {
                columns: ["Policy Type"],
                order: 1,
                successMessage: "Drag ‘Policy Type’ and drop it in the 'Bars' section."
            },
            "Size": {
                columns: ["Claim Amount"],
                aggregate: ["Average"],
                order: 2,
                successMessage: "Great! Now drag ‘Claim Amount’ and drop it in the 'Size' section and set it to 'Average'."
            },
            "Series": {
                columns: ["Approval Status"],
                order: 3,
                successMessage: "Amazing! Now drag ‘Approval Status’ and drop it in the 'Series' section."
            }

        },
    },
    "Decline in Credit Card Spending": {
        welcomeTitle: "Decline in Credit Card Spending",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "What’s behind the dip in credit card spending among younger customers? Could spending categories, reward points, or transaction frequency provide clues? Let’s dive into the data and uncover insights!",
        description: "Analyze spending patterns to understand declining credit card usage among younger customers. Identify trends across spending categories, reward incentives, and transaction frequency to devise effective promotional strategies.",
        beforeStarting: "Let’s begin by exploring the spending trends for the 18–25 age group. Are certain categories seeing a sharper decline?",
        beforeStarting2: "Could reward points or transaction frequency be influencing these trends? Let’s investigate!",
        onSuccess: "Well done! Do you notice any significant spending declines in specific categories or age groups?",
        quizQuestion: "Based on the insights provided, which actions should the credit card provider prioritize to address declining spending among younger customers?",
        options: [
            "Introduce promotional offers for Travel and Entertainment categories targeting the 18–25 age group.",
            "Increase reward points for all transactions, regardless of category.",
            "Focus solely on encouraging grocery spending among younger customers.",
            "Ignore category-specific trends and increase credit limits for younger customers."
        ],
        correctAnswer: [
            "Introduce promotional offers for Travel and Entertainment categories targeting the 18–25 age group.",
            "Focus solely on encouraging grocery spending among younger customers."
        ],
        correctGuessMessage: "That’s correct! Addressing category-specific trends with targeted promotions and incentives will help drive spending among younger customers. Great job!",
        defaultBarIndex: 2,
        chartingSequence: {
            "Bars": {
                columns: ["Customer Age Group"],
                order: 1,
                successMessage: "Drag ‘Customer Age Group’ and drop it in the 'Bars' section."
            },
            "Size": {
                columns: ["Transaction Amount"],
                aggregate: ["Sum"],
                order: 2,
                successMessage: "Great! Now drag ‘Transaction Amount’ and drop it in the 'Size' section and set it to 'Sum'."
            },
            "Series": {
                columns: ["Category"],
                order: 3,
                successMessage: "Amazing! Now drag ‘Category’ and drop it in the 'Series' section."
            }
        },
    },
    "Custom Problem": {
        welcomeTitle: "Decline in Credit Card Spending",
        welcomeSecondaryTitle: "Problem Statement",
        welcomeMessage: "What’s behind the dip in credit card spending among younger customers? Could spending categories, reward points, or transaction frequency provide clues? Let’s dive into the data and uncover insights!",
        description: "Analyze spending patterns to understand declining credit card usage among younger customers. Identify trends across spending categories, reward incentives, and transaction frequency to devise effective promotional strategies.",
        beforeStarting: "Let’s begin by exploring the spending trends for the 18–25 age group. Are certain categories seeing a sharper decline?",
        beforeStarting2: "Could reward points or transaction frequency be influencing these trends? Let’s investigate!",
        onSuccess: "Well done! Do you notice any significant spending declines in specific categories or age groups?",
        quizQuestion: "Based on the insights provided, which actions should the credit card provider prioritize to address declining spending among younger customers?",
        options: [
            "Introduce promotional offers for Travel and Entertainment categories targeting the 18–25 age group.",
            "Increase reward points for all transactions, regardless of category.",
            "Focus solely on encouraging grocery spending among younger customers.",
            "Ignore category-specific trends and increase credit limits for younger customers."
        ],
        correctAnswer: [
            "Introduce promotional offers for Travel and Entertainment categories targeting the 18–25 age group.",
            "Focus solely on encouraging grocery spending among younger customers."
        ],
        correctGuessMessage: "That’s correct! Addressing category-specific trends with targeted promotions and incentives will help drive spending among younger customers. Great job!",
        defaultBarIndex: 2
    }


}

export default ProblemList;