import React from "react";

const CollapseIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="34"
      height="34"
      rx="4.25"
      fill="url(#paint0_linear_874_1517)"
      fillOpacity="0.16"
    />
    <rect
      x="0.5"
      y="0.5"
      width="33"
      height="33"
      rx="3.75"
      stroke="white"
      strokeOpacity="0.08"
    />
    <path
      d="M15.4942 19.6552L9.84112 25.3083H13.6576V26.9727H8.66439C7.74648 26.9727 7 26.2262 7 25.3083V20.3152H8.66439V24.1316L14.3175 18.4785L15.4942 19.6552ZM25.3083 7H20.3152V8.66439H24.1316L18.4785 14.3175L19.6552 15.4942L25.3083 9.84112V13.6576H26.9727V8.66439C26.9727 7.74648 26.2262 7 25.3083 7Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_874_1517"
        x1="-9.65909"
        y1="-28.8679"
        x2="50.4408"
        y2="2.52376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D7EDED" />
        <stop offset="1" stopColor="#CCEBEB" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default CollapseIcon;
