import React from "react";
import { Typography } from "@mui/material";

const CustomTypography = ({ children, sx, ...props }) => {
  return (
    <Typography sx={{ ...sx }} {...props}>
      {children}
    </Typography>
  );
};

export default CustomTypography;
