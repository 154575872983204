import React from "react";
import { Button } from "@mui/material";

const BtnMain = ({ children, onClick, sx, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        height: "36px",
        width: "100%",
        // maxWidth: "120px",
        background: "linear-gradient(91deg, #00ff85 7.38%, #00e5ff 96.85%)",
        borderRadius: "12px",
        border: "none",
        color: "#000",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "100%",
        marginRight: "15px",
        zIndex: 10,
        textTransform: "none",
        "&:hover": {
          background: "linear-gradient(91deg, #00e5ff 7.38%, #00ff85 96.85%)",
        },
        ...sx
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

const BtnMainLanding = ({ children, onClick, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        width: "100%",
        height: "52px",
        maxWidth: "200px",
        background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
        borderRadius: "4px",
        border: "none",
        color: "#000",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontSize: "15.217px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "100%",
        marginRight: "10px",
        zIndex: 10,
        textTransform: "none",
        "&:hover": {
          background: "linear-gradient(91deg, #00e5ff 7.38%, #00ff85 96.85%)",
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

const BtnSecondary = ({ children, onClick, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        height: "52px",
        width: "100%",  // Makes the button width take up 100% of its parent container
        maxWidth: "200px", // Maximum width to prevent it from getting too wide
        gap: "10.87px",
        background: "linear-gradient(100deg, #fcfcfc 9.75%, #bbb 53.74%)",
        borderRadius: "4px",
        border: "none",
        color: "#000",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontSize: "15.217px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "100%",
        zIndex: 10,
        textTransform: "none", // Ensures text is not capitalized
        "&:hover": {
          background: "linear-gradient(100deg, #bbb 9.75%, #fcfcfc 53.74%)",
        },
        "@media (max-width: 600px)": {
          fontSize: "14px",  // Adjust font size for small screens
          height: "45px",    // Adjust height for small screens
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export { BtnMain, BtnSecondary, BtnMainLanding };
