import { motion } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import HomeImage from "../assets/img/datalyze_logo.webp";
import Coins from "../assets/img/Coins.png";
import { useNavigate } from "react-router-dom";
import Avatar from "./Avatar";
import { useSelector } from "react-redux";
import "./GameList.css";
import "swiper/css/navigation";
import Introduction from "./Introduction";
import CustomTypography from "../mui/customTypography";
import CustomCard from "../mui/customCard";
import { Card, Typography, Box, Grid } from "@mui/material";
import { useGetAllProblemsQuery, useGetLeaderboardQuery } from "../store/services/datalyzeProblems";
import Sidebar from "../mui/sidebar";

const GameList = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const jwt = useSelector((state) => state?.user?.jwt);
    const userDetails = useSelector((state) => state?.user?.userDetails);
    const coinsCount = useSelector((state) => state?.user?.coinsCount);
    const { data } = useGetAllProblemsQuery({ location: "app", token: jwt });
    const { data: leaderboardData } = useGetLeaderboardQuery({ token: jwt });

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.2, ease: "easeOut" },
        },
    };
    const navigate = useNavigate();
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [speechEnabled, setSpeechEnabled] = useState(true);
    const speechRef = useRef(null);

    const setVoice = () => {
        if (isSpeaking) {
            window.speechSynthesis.cancel();
            setIsSpeaking(false);
        } else if (speechEnabled) {
            // Prevent multiple initializations
            window.speechSynthesis.cancel();
            speechRef.current = new SpeechSynthesisUtterance("Welcome to Datalyze! Please select a problem statement to get started. You can also choose to upload your own dataset by clicking 'Custom Problem'.");
            speechRef.current.onstart = () => setIsSpeaking(true);
            speechRef.current.onend = () => setIsSpeaking(false);
            window.speechSynthesis.speak(speechRef.current);
        }

        setSpeechEnabled((prev) => !prev);
    };

    useEffect(() => {
        setVoice();
    }, []); // Dependency array is empty to ensure it runs only once


    useEffect(() => {
        const hasSeenModal = localStorage.getItem("hasSeenModal");

        if (!hasSeenModal) {
            const timer = setTimeout(() => {
                setModalOpen(true);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, []);

    const handleClose = () => {
        setModalOpen(false);
        localStorage.setItem("hasSeenModal", "true");
    };

    return (
        <div
            style={{
                width: "100%",
                minHeight: "100vh",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                position: "relative",
            }}
        >
            {isModalOpen && (
                <div
                    className="intro-modal-overlay"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <motion.div
                        className="intro-modal-content"
                        style={{
                            maxWidth: "1000px",
                            height: "500px",
                            position: "relative",
                        }}
                        initial={{ y: "100%", opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: "100%", opacity: 0 }}
                        transition={{
                            type: "spring",
                            stiffness: 300,
                            damping: 30,
                            duration: 0.5,
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
                    >
                        <button
                            className="close-button"
                            onClick={handleClose}
                            aria-label="Close Modal"
                        >
                            ×
                        </button>
                        <Introduction isModalOpen={isModalOpen} onClose={handleClose} />
                    </motion.div>
                </div>
            )}

            <div
                className="sticky-header"
            >
                <div style={{ margin: "0 auto", marginBottom: "50px" }}>
                    <img
                        className="home-image"
                        src={HomeImage}
                    />
                </div>

                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    <Card
                        className="coins-card"
                        sx={{
                            // width: "fit-content",
                            height: "75px",
                            borderRadius: "16px",
                            borderTop: "1px solid rgba(255, 255, 255, 0.04)",
                            backgroundColor: "rgba(26, 29, 33, 0.4)",
                            boxShadow:
                                "16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset, 0px 24px 64px -16px rgba(0, 0, 0, 0.24), 0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0 10px",
                            boxSizing: "border-box",
                        }}
                    >
                        <Box
                            sx={{
                                width: "108px",
                                height: "100px",
                                mb: 5,
                            }}
                        >
                            <img
                                className="coin-image"
                                src={Coins}
                                alt="Coins"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        </Box>
                        <Typography
                            className="total-coins-text"
                            variant="body2"
                            sx={{
                                fontSize: "15px",
                                fontWeight: 600,
                                lineHeight: "24px",
                                color: "#8E8C91",
                            }}
                        >
                            <span className="text-number" style={{ fontSize: "28px", fontWeight: 800, color: "#fff" }}>
                                <motion.span>{coinsCount}</motion.span>
                            </span>
                            <span className="total"> Total</span>
                            <br />
                            <span className="coins-text">Coins Available</span>
                        </Typography>
                        <Box
                            className="redeem-box"
                            sx={{
                                display: { xs: "none", sm: "none", md: "flex" },
                                position: "relative",
                                right: "-10px",
                                backgroundColor: "#F97403",
                                width: "85px",
                                height: "30px",
                                borderTopLeftRadius: "13px",
                                borderBottomLeftRadius: "13px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    color: "#1E2118",
                                }}
                            >
                                Redeem
                            </Typography>
                        </Box>
                    </Card>
                </motion.div>
            </div>


            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 50px 20px 50px" }}>
                <div>
                    <Sidebar userDetails={userDetails} leaderboardData={leaderboardData?.data} />
                </div>
                <div style={{ paddingLeft: "60px", }}>
                    <Grid>
                        <CustomTypography
                            sx={{
                                fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.75rem" },
                                fontWeight: 800,
                                lineHeight: "24px",
                                color: "#82DBF7",
                            }}
                        >
                            Problem Solving through data
                        </CustomTypography>
                        <Box
                            sx={{
                                mt: 4,
                                display: "flex",
                                flexDirection: 'row',
                                justifyContent: "start",
                                flexWrap: "wrap",
                                width: "100%",
                                margin: "0 auto",
                                paddingTop: "50px",
                            }}
                        >
                            {data?.data?.map((item, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={3} // Ensures 4 cards per row on medium screens
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginRight: "15px",
                                            flex: "0 1 250px",
                                            marginBottom: "20px"
                                        }}
                                    >
                                        <motion.div
                                            initial="hidden"
                                            animate="visible"
                                            variants={cardVariants}
                                            whileHover={{
                                                scale: 1.08,
                                                border: "1px solid yellow",
                                                transition: { duration: 0.2, ease: "easeOut" },
                                            }}
                                            style={{ borderRadius: "12px" }}
                                            onClick={() => {
                                                navigate(`/gamified-data-analytics/${item?._id}`);
                                            }}
                                        >
                                            <CustomCard firstImage={item?.icon} cardText={item?.title} />
                                        </motion.div>
                                    </Grid>
                                )
                            })}
                        </Box>
                    </Grid>
                </div>
            </div>



            <div onClick={setVoice}>
                <Avatar isSpeaking={isSpeaking} onClick={setVoice} />
            </div>
        </div >
    );
};

export default GameList;
