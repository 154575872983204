import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import ColumnCard from './ColumnCard'
import ChartDimensionCard from './ChartDimensionCard'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import arrayInsert from 'array-insert'
import { motion, AnimatePresence } from "framer-motion";
import { getDefaultDimensionAggregation } from '@rawgraphs/rawgraphs-core'
import ChartPreviewWithOptions from "../ChartPreviewWIthOptions/ChartPreviewWithOptions";
import "./styles.css";

function removeIndex(mapping, i) {
    if (!mapping) return {}; // Handle undefined mapping gracefully

    let nextConfig = mapping.config
        ? {
            ...mapping.config,
            aggregation: mapping.config.aggregation.filter((_, j) => j !== i),
        }
        : null;

    return {
        ...mapping,
        ids: (mapping.ids || []).filter((_, j) => j !== i),
        value: (mapping.value || []).filter((_, j) => j !== i),
        config: nextConfig,
    };
}

function arrayReplace(arr, i, value) {
    return arr.map((item, j) => (j === i ? value : item))
}

function handleReplaceLocalMapping(
    nextId,
    prev,
    fromDimension,
    toDimension,
    fromIndex,
    toIndex,
    dimensions,
    dataTypes,
    multiple = false,
) {
    const fromMapping = prev[fromDimension] || {};
    const removedItem = {
        aggregation: fromMapping.config?.aggregation?.[fromIndex],
        value: fromMapping.value?.[fromIndex],
    };

    let moveFn = multiple ? arrayInsert : arrayReplace;

    const prevToMapping = prev[toDimension] || {};
    const toDimensionMapping = {
        ...prevToMapping,
        ids: moveFn(prevToMapping.ids || [], toIndex, nextId),
        value: moveFn(prevToMapping.value || [], toIndex, removedItem.value),
    };

    const dimension = dimensions[toDimension];
    if (dimension?.aggregation) {
        let newAggregation =
            removedItem.aggregation ||
            getDefaultDimensionAggregation(
                dimension,
                dataTypes[removedItem.value]
            );

        toDimensionMapping.config = {
            aggregation: moveFn(
                get(prevToMapping, 'config.aggregation', []),
                toIndex,
                newAggregation
            ),
        };
    }

    return {
        ...prev,
        [fromDimension]: removeIndex(fromMapping, fromIndex),
        [toDimension]: toDimensionMapping,
    };
}

function DataMapping({
    dataTypes,
    dimensions,
    visualOptions,
    setVisualOptions,
    setRawViz,
    setMappingLoading,
    currentChart,
    dataSet,
    setMessages,
    messages,
    chatExpanded,
    charts,
    problemData,
    isCustomProblem
},
    ref) {
    const [showAnimation, setShowAnimation] = useState(true);
    const [mapping, setMapping] = useState({});
    const [mappingNew, setMappingNew] = useState({})
    const [localMappding, setLocalMapping] = useState(mapping);
    const [currentChartSequence, setCurrentChartSequence] = useState({});
    const currentChartingSequence = Object.assign({}, problemData?.chartingSequence);
    const [expandStatus, setExpandStatus] = useState(true);
    let isTheDefaultGraphSelected = currentChart?.metadata?.name === charts?.[problemData?.defaultBarIndex]?.metadata?.name;
    let successfullMapping = {};

    const handleExpandClick = (status => {
        setExpandStatus(status);
    })

    !isCustomProblem && Object.keys(currentChartingSequence)?.forEach((type) => {
        successfullMapping = Object.assign({}, successfullMapping, {
            [type?.toLowerCase()]: currentChartingSequence?.[type]?.columns
        })
    });
    useEffect(() => {
        if (!isCustomProblem) {
            Object.keys(currentChartingSequence)?.forEach((item) => {
                if (currentChartingSequence?.[item]?.order === 1 && isTheDefaultGraphSelected) {
                    setCurrentChartSequence(Object.assign({}, currentChartingSequence?.[item], {
                        type: item
                    }));
                    if (currentChartingSequence?.[item]?.successMessage) {
                        setMessages([...messages, {
                            text: currentChartingSequence?.[item]?.successMessage,
                            type: "Sensei"
                        }])
                    }
                }
            })
        }

    }, [])

    const updateMapping = useCallback(
        (dimension, mappingConf, isLocal) => {
            // Local mapping update
            setLocalMapping((prev) => ({
                ...prev,
                [dimension]: mappingConf,
            }));

            // Global mapping update
            if (!isLocal) {
                setMappingNew((prev) => {
                    return {
                        ...prev,
                        [dimension]: mappingConf,
                    };
                })
            }

            let latestMapping = {
                ...localMappding,
                [dimension]: mappingConf
            };

            let userMappedColumns = {}
            Object.keys(latestMapping)?.forEach((type) => {
                userMappedColumns = Object.assign({}, userMappedColumns, {
                    [type?.toLowerCase()]: latestMapping?.[type?.toLowerCase()]?.value
                })
            })


            if (dimension?.toLowerCase() === currentChartSequence?.type?.toLowerCase() && !isCustomProblem) {
                Object.keys(currentChartingSequence)?.forEach((item) => {
                    if (currentChartingSequence?.[item]?.order === (currentChartSequence?.order + 1)) {
                        setCurrentChartSequence(Object.assign({}, currentChartingSequence?.[item], {
                            type: item
                        }));
                        if (currentChartingSequence?.[item]?.successMessage) {
                            setMessages([...messages, {
                                text: currentChartingSequence?.[item]?.successMessage,
                                type: "Sensei"
                            }])
                        }
                        setShowAnimation(true);
                        return;
                    } else if (JSON.stringify(userMappedColumns) === JSON.stringify(successfullMapping)) {
                        setShowAnimation(false);
                        setMessages([...messages, {
                            text: "Good job! You have created your first graph! Now you are ready to explore and create more graphs to find interesting insights. Click on 'Proceed' whenever you are done with your analysis.",
                            type: "Sensei"
                        }])
                    }
                })
            }
        },
        [setMapping, currentChartSequence]
    );


    const replaceDimension = useCallback(
        (fromDimension, toDimension, fromIndex, toIndex, multiple = false) => {
            const nextId = uniqueId()
            if (multiple) {
                setDraggingId(nextId)
            }
            setLocalMapping((prev) => {
                return handleReplaceLocalMapping(
                    nextId,
                    prev,
                    fromDimension,
                    toDimension,
                    fromIndex,
                    toIndex,
                    dimensions,
                    dataTypes,
                    multiple
                )
            })
            if (!multiple) {
                setMapping((prev) => {
                    return handleReplaceLocalMapping(
                        nextId,
                        prev,
                        fromDimension,
                        toDimension,
                        fromIndex,
                        toIndex,
                        dimensions,
                        dataTypes
                    )
                })
            }
        },
        [dataTypes, dimensions, setMapping]
    )
    const [draggingId, setDraggingId] = useState(null)
    const rollbackLocalMapping = useCallback(() => {
        setLocalMapping(mapping)
        setDraggingId(null)
    }, [mapping])

    // const commitLocalMapping = useCallback(() => {
    //   console.log('COMMIT!', localMappding)
    //   setMapping(localMappding)
    //   setDraggingId(null)
    // }, [localMappding, setMapping])
    const commitLocalMapping = () => {
        // setMapping()
        setMapping(lastMapping.current)
        setDraggingId(null)
    }

    const lastMapping = useRef()
    useEffect(() => {
        lastMapping.current = localMappding
    })

    useImperativeHandle(ref, () => ({
        clearLocalMapping: () => {
            setLocalMapping({})
        },
    }))

    return (
        <>
            <AnimatePresence>
                {expandStatus && (
                    <DndProvider backend={HTML5Backend}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start" }}>
                            <div style={{ marginRight: "25px" }}>
                                <h5 className="text-uppercase">Dimensions</h5>
                                {Object.entries(dataTypes).map(([columnName, dataType], index) => (
                                    <ColumnCard
                                        key={columnName}
                                        dimensionName={columnName}
                                        dimensionType={dataType}
                                        index={index}
                                        commitLocalMapping={commitLocalMapping}
                                        rollbackLocalMapping={rollbackLocalMapping}
                                        currentChartSequence={currentChartSequence}
                                        showAnimation={showAnimation}
                                        isTheDefaultGraphSelected={isTheDefaultGraphSelected}
                                    />
                                ))}
                            </div>
                            <div>
                                <h5 className="text-uppercase">Chart Variables</h5>
                                <div className="sticky-top" style={{ top: 'calc(var(--header-height) + 16px)' }}>
                                    {dimensions.map((d) => (
                                        <ChartDimensionCard
                                            key={d.id}
                                            dimension={d}
                                            dataTypes={dataTypes}
                                            mapping={localMappding[d.id] || {}}
                                            setMapping={(mappingConf, isLocal = false) => updateMapping(d.id, mappingConf, isLocal)}
                                            commitLocalMapping={commitLocalMapping}
                                            rollbackLocalMapping={rollbackLocalMapping}
                                            draggingId={draggingId}
                                            setDraggingId={setDraggingId}
                                            replaceDimension={replaceDimension}
                                            localMappding={localMappding}
                                            isTheDefaultGraphSelected={isTheDefaultGraphSelected}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </DndProvider>
                )}
            </AnimatePresence>
            <motion.div
                key={expandStatus ? true : false}
                initial={{ opacity: 0, y: -20 }}
                animate={{
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    type: "spring",
                    stiffness: 100,
                    damping: 5,
                    duration: 0.3,
                }}
            >
                <ChartPreviewWithOptions
                    chart={currentChart}
                    dataset={dataSet.dataset}
                    dataTypes={dataSet.dataTypes}
                    mapping={mappingNew}
                    visualOptions={visualOptions}
                    setVisualOptions={setVisualOptions}
                    setRawViz={setRawViz}
                    setMappingLoading={setMappingLoading}
                    chatExpanded={chatExpanded}
                    onExpandClick={handleExpandClick}
                    expandStatus={expandStatus}
                />
            </motion.div>
        </>

    )
}

export default React.forwardRef(DataMapping);