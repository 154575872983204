import { createSlice } from '@reduxjs/toolkit';
const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: 0,
        jwt: localStorage.getItem("jwt"),
        userDetails: {},
        coinsCount: 0
    },
    reducers: {
        setJwtToken: (state, action) => {
            localStorage.setItem("jwt", action?.payload?.token);
            state.jwt = action?.payload?.token
        },
        setUserDetails: (state, action) => {
            state.userDetails = action?.payload
        },
        setCoinsCount: (state, action) => {
            state.coinsCount = action?.payload?.coinsCount
        }
    },
});

export const { setJwtToken, setUserDetails, setCoinsCount } = userSlice.actions;
export default userSlice.reducer;