import React, { useState } from "react";
// import { Helmet } from "react-helmet-async";
import AILottie from "../../assets/lotties/MachineAI/AI.json";
import Lottie from "lottie-react";
import Papa from "papaparse";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import loadable from "@loadable/component";
import dataLyze from "../../assets/img/LLMS/datalyzeLogo.png?as=webp";
import MHDarkLogo from "../../assets/img/LLMS/MH-Gen-AI-Dark.png?as=webp";
// import { useHistory } from "react-router-dom";
import "./LLMS.css";

const ContactUs = loadable(() => import("./components/ContactUs"));
const FileUpload = loadable(() => import("./components/FileUpload"));
const HistoryAccordion = loadable(() =>
  import("./components/HistoryAccordion")
);
// const MachinehackAI = loadable(() => import("@views/MachineAI"));
const SearchBar = loadable(() => import("./components/SearchBar"));
const ToastMessages = loadable(() => import("./components/ToastMessage"));

export default function LLMS() {
  // const WEB_URL = "https://datalyse.machinehack.com";
  const LLMSBackend = "https://api.dev.llms.machinehack.com";
  const [searchValue, setSearchValue] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Chatgpt");
  const [fileUploading, setFileUploading] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [asks, setAsks] = useState(10);
  const [showContactUs, setShowContactUs] = useState(false);
  const [csvLink, setCsvLink] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  // const history = useHistory();
  const [showProcessing, setShowProcessing] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [parameterValues, setParameterValues] = useState({
    temperature: 0,
    top_p: 1,
    max_tokens: 125,
  });
  const [showToast, setShowToast] = useState({
    show: false,
    message: "",
    duration: 0,
    variant: "",
  });
  const handleAsk = async (searchProp, regenerateObject, index) => {
    if (asks > 0 && csvLink && (searchValue || searchProp)) {
      setAsks(asks - 1);
    }
    setShowProcessing(true);
    // setCsvData([]);
    try {
      if (csvLink && (searchValue || searchProp)) {
        const params = new URLSearchParams();
        // params.append("s3_link", csvLink);
        params.append("data_array", JSON.stringify(csvData));
        params.append(
          "question",
          searchProp && searchProp !== null ? searchProp : searchValue
        );
        params.append("temperature", parameterValues.temperature);
        params.append("top_p", parameterValues.top_p);
        params.append("max_tokens", parameterValues.max_tokens);
        const response = await axios.post(
          `${LLMSBackend}/agents/v1/csv/`,
          params
        );
        setShowSearchBar(false);
        if (regenerateObject) {
          const data = [...historyData];
          data[index] = regenerateObject;
          setHistoryData(data);
        } else {
          setHistoryData([
            ...historyData,
            { question: searchValue, answer: response.data },
          ]);
        }
      } else {
        setShowToast({
          show: true,
          message:
            csvLink === "" ? "No file selected" : "Query field cannot be empty",
          duration: 2000,
          variant: "warning",
        });
      }
      setShowProcessing(false);
    } catch (err) {
      setShowToast({
        show: true,
        message: "This model's maximum context length is 4097 tokens",
        duration: 2000,
        variant: "warning",
      });
      setShowProcessing(false);
    }
  };
  const handleAskAgain = () => {
    setSearchValue("");
    setShowSearchBar(true);
  };
  const OnFileUpload = async (acceptedFiles) => {
    Papa.parse(acceptedFiles[0], {
      header: true,
      complete: (result) => {
        setCsvData(result.data);
      },
    });
    setFileUploading(true);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (acceptedFiles[0]) {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      try {
        const response = await axios.post(
          `${LLMSBackend}/agents/v1/upload/`,
          formData,
          config
        );
        setFileUploading(false);
        if (response?.data?.url) {
          setCsvLink(response?.data?.url);
          setSelectedFile([...selectedFile, ...acceptedFiles]);
        } else {
          setShowToast({
            show: true,
            message: "An error occurred during file upload",
            duration: 2000,
            variant: "warning",
          });
        }
      } catch (error) {
        setFileUploading(false);
        setShowToast({
          show: true,
          message: "An error occurred during file upload",
          duration: 2000,
          variant: "warning",
        });
      }
    }
  };
  const handleRemoveFile = (e) => {
    e.stopPropagation();
    setCsvLink(null);
    setCsvData([]);
    setSelectedFile([]);
    setSearchValue("");
    setShowSearchBar(true);
  };


  return (
    <>
      {/* <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>MachineHack Datalyse </title>
        <meta
          name="description"
          content="Introducing Datalyse, a cutting-edge Generative AI product that revolutionizes data analysis. With Datalyse, effortlessly transform your CSV data into actionable insights and gain deep insights from your data effortlessly. Interactively query the data using natural language commands and receive real-time and insightful responses. Unlock the hidden knowledge within your CSV files, simplify your data analysis process, and make informed decisions with Datalyse's user-friendly solution. Experience a new level of data-driven intelligence today."
        />
        <meta
          property="og:title"
          content="MachineHack Datalyse"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${WEB_URL}/custom-upload`} />
        <meta
          property="og:image"
          content="https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/ogImages/Machinehack-Datalyze.jpg"
        />
        <meta
          property="og:description"
          content="Introducing Datalyse, a cutting-edge Generative AI product that revolutionizes data analysis. With Datalyse, effortlessly transform your CSV data into actionable insights and gain deep insights from your data effortlessly. Interactively query the data using natural language commands and receive real-time and insightful responses. Unlock the hidden knowledge within your CSV files, simplify your data analysis process, and make informed decisions with Datalyse's user-friendly solution. Experience a new level of data-driven intelligence today."
        />
        <meta property="og:url" content={`${WEB_URL}/custom-upload`} />
        <meta property="og:site_name" content="Machinehack Generative AI" />
        <meta
          name="twitter:title"
          content="MachineHack Datalyse | Generative AI"
        />
        <meta
          name="twitter:description"
          content="Introducing Datalyse, a cutting-edge Generative AI product that revolutionizes data analysis. With Datalyse, effortlessly transform your CSV data into actionable insights and gain deep insights from your data effortlessly. Interactively query the data using natural language commands and receive real-time and insightful responses. Unlock the hidden knowledge within your CSV files, simplify your data analysis process, and make informed decisions with Datalyse's user-friendly solution. Experience a new level of data-driven intelligence today."
        />
        <meta
          name="twitter:image"
          content="https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/ogImages/Machinehack-Datalyze.jpg"
        />
        <link rel="canonical" href={`${WEB_URL}/custom-upload`} />
      </Helmet> */}
      <div className="bootstrap-iso">
        <div className="llmsHld">
          <div className="llmsHeader d-flex justify-content-center align-items-center py-1">
            {/* <div className="ml-3 position-absolute llmsMhLogo" onClick={()=>history.push("/feed")}> */}
            <div
              className="ml-3 position-absolute llmsMhLogo"
            // onClick={() => history.push("/")}
            >
              <div className="logo-hld">
                <img
                  className="mh-logo feed-header-logo img-fluid"
                  src={MHDarkLogo}
                  alt="Machine Hack Logo"
                  loading="lazy"
                />
              </div>
            </div>
            <img src={dataLyze} alt="dataLyze" loading="lazy" /> <div></div>
          </div>
          <div className="pageheading d-flex justify-content-center">
            <h1 className="gradientText text-center mb-0">
              Data Exploration at Your Fingertips
            </h1>
          </div>
          <div
            className={`d-flex flex-column ${selectedFile[0] && csvData?.length === 0
              ? `centerSectionTable`
              : `centerSectionPadding`
              } centerSection col-10 m-auto position-relative `}
          >
            <FileUpload
              selectedFile={selectedFile}
              OnFileUpload={OnFileUpload}
              fileUploading={fileUploading}
              csvData={csvData}
              handleRemoveFile={handleRemoveFile}
            />
            {selectedFile[0] && selectedOption && historyData?.length > 0 && (
              <HistoryAccordion
                historyData={historyData}
                handleAsk={handleAsk}
              />
            )}
            {showSearchBar && (
              <SearchBar
                searchValue={searchValue}
                historyData={historyData}
                setSearchValue={setSearchValue}
                handleAsk={handleAsk}
                asks={asks}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                historySearch={historyData?.length > 0 ? true : false}
                parameterValues={parameterValues}
                setParameterValues={setParameterValues}
              />
            )}
            {!showSearchBar && (
              <>
                <div
                  className={`llmsSearchHld askAgainSection cursor-pointer mt-4`}
                  onClick={handleAskAgain}
                >
                  <div
                    className="llmsSearchInner text-left d-flex justify-content-center"
                    style={{ height: "40px" }}
                  >
                    <div className="askAgainBtn d-flex align-items-center mx-2">
                      <Lottie
                        animationData={AILottie}
                        loop={true}
                        style={{
                          width: "82px",
                        }}
                      />{" "}
                      <p className="askAgainText mb-0"> Ask New Question </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="llmsFooter d-flex justify-content-center align-items-center py-2">
            <span className="mb-0 mr-4 thinFont">
              Get in touch for more information{" "}
            </span>{" "}
            <button
              className="activeBtn px-3 py-2"
              onClick={() => setShowContactUs(true)}
            >
              Contact us{" "}
            </button>{" "}
          </div>
          <ContactUs
            open={showContactUs}
            handleClose={() => setShowContactUs(false)}
          />
          <Modal
            open={showProcessing}
            onClose={() => setShowProcessing(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="machineAiModal"
          >
            <Box>
              {/* <MachinehackAI message={"Loading..."} /> */}
            </Box>
          </Modal>
          {showToast.show && (
            <ToastMessages
              toastMessage={showToast}
              setToastMessage={setShowToast}
              isDarkMode={true}
            />
          )}
        </div>
      </div>
    </>
  );
}
