import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userAuthApis, } from './services/userLogin';
import { datalyzeProblemApis } from './services/datalyzeProblems';
import userSlice from './slices';

export const store = configureStore({
    reducer: {
        user: userSlice, // Add your custom reducers
        [userAuthApis.reducerPath]: userAuthApis.reducer, // Add RTK Query reducer
        [datalyzeProblemApis.reducerPath]: datalyzeProblemApis.reducer, // Add RTK Query reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(userAuthApis.middleware).concat(datalyzeProblemApis.middleware), // Add RTK Query middleware
});

// Set up listeners for automatic cache invalidation and refetching
setupListeners(store.dispatch);