import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import DataTable from './DataTable';
import Delete from '../assets/img/delete.png';
// import { useSelector } from "react-redux";
// import ProblemList from "../data/problems";
import DataMapping from "../components/RawGraph/DataMapping"
import './DataAnalysis.css';

const DataAnalysis = ({
  task,
  tableData,
  setTableData,
  showDeleteToast,
  setShowDuplicateDataToast,
  selectedAction,
  setSelectedAction,
  handleCleanupData,
  showActionCreator,
  showQuiz,
  quizAnswers,
  setQuizAnswers,
  showChartSelector,
  setShowChartSelector,
  setShowAnalysis,
  charts,
  currentChart,
  dataMappingRef,
  dataSet,
  visualOptions,
  setVisualOptions,
  setRawViz,
  setMappingLoading,
  handleChartChange,
  setMessages,
  messages,
  chatExpanded,
  assessments,
  problemData,
  isCustomProblem
}) => {

  // const currentProblem = useSelector((state) => state?.user?.value);
  // const problemDetails = ProblemList?.[currentProblem];
  const columns = React.useMemo(() => {
    const dataTypeKeys = dataSet?.dataTypes ? Object.keys(dataSet.dataTypes) : [];

    const columnDefinitions = dataTypeKeys.map((column) => ({
      Header: column,
      accessor: column,
      type: dataSet?.dataTypes[column] === "string" ? "string" : dataSet?.dataTypes[column]?.type,
    }));

    const actionsColumn = {
      Header: 'Actions',
      Cell: ({ row }) => (
        <div>
          <button onClick={() => handleDeleteRow(row.index)}>
            <img src={Delete} alt="Delete" />
          </button>
        </div>
      ),
    };

    return showActionCreator ? [...columnDefinitions, actionsColumn] : [...columnDefinitions]
  }, [dataSet?.dataTypes, showActionCreator]);


  const handleAddRow = (value) => {
    setTableData([value, ...tableData]);
  };

  const handleEditRow = (index, value) => {
    const newData = [...tableData];
    newData[index] = value;
    setTableData(newData);
  };

  const handleDeleteRow = (index) => {
    const newData = [...tableData];
    newData.splice(index, 1);
    setTableData(newData);
  };


  const tableInstance = useTable({ columns, data: tableData }, useFilters, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of rows, use page
    prepareRow,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  return (
    <>
      <div className="data-analysis">
        {(task === "linked" || task === "cleanup" || task === "proceed") && (
          <DataTable
            handleAddRow={handleAddRow}
            setFilter={setFilter}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            handleEditRow={handleEditRow}
            handleDeleteRow={handleDeleteRow}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            data={dataSet?.dataset}
            dataSet={dataSet}
            showDeleteToast={showDeleteToast}
            setShowDuplicateDataToast={setShowDuplicateDataToast}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            handleCleanupData={handleCleanupData}
            showActionCreator={showActionCreator}
          />
        )}

        {task === "analyse" && !showQuiz && showChartSelector ?
          <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "start" }}>
            <div style={{ maxWidth: "420px", height: "520px", gap: "16px", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", marginRight: "20px", overflow: "auto" }}>
              {charts?.map((item) => {
                return (
                  <div style={{
                    width: "200px",
                    height: "56px",
                    background: currentChart?.metadata?.name === item?.metadata?.name ? "#010C12" : "rgba(54, 58, 61, 0.20)",
                    border: currentChart?.metadata?.name === item?.metadata?.name ? "0.5px solid #00FF85" : "0.5px solid #363A3D",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    paddingLeft: "15px"
                  }}
                    onClick={() => {
                      handleChartChange(item);
                    }}
                  >
                    <p style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      color: "#fff"
                    }}>{item?.metadata?.name}</p>
                  </div>)
              })}
            </div>
            <div style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "423px",
              borderRadius: "10px",
              backgroundColor: "#FFF",
              padding: "15px",
            }}>
              <img src={currentChart?.metadata?.thumbnail} alt={currentChart?.metadata?.name} style={{ marginBottom: "40px", maxWidth: "200px" }} />
              <h4 style={{
                fontSize: "14px",
                lineHeight: "24px",
                color: "#000",
                fontWeight: 500,
                marginBottom: "10px"
              }}>
                {currentChart?.metadata?.name}
              </h4>
              <p style={{
                fontSize: "12px",
                lineHeight: "16px",
                color: "#8E8C91",
                fontWeight: 500
              }}>{currentChart?.metadata?.description}</p>
              <button
                style={{
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: 700,
                  lineHeight: "18px",
                  width: "104px",
                  height: "34px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  border: "none",
                  background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                  marginTop: "20px"
                }}
                onClick={() => {
                  setShowChartSelector(false);
                  setTimeout(() => {
                    setShowAnalysis(true);
                  }, 500)
                }}
              >
                Proceed Now
              </button>
            </div>
          </div>
          : task === "analyse" && !showQuiz && !showChartSelector &&
          <>
            <DataMapping
              ref={dataMappingRef}
              dimensions={currentChart.dimensions}
              dataTypes={dataSet.dataTypes}
              visualOptions={visualOptions}
              setVisualOptions={setVisualOptions}
              setRawViz={setRawViz}
              setMappingLoading={setMappingLoading}
              currentChart={currentChart}
              dataSet={dataSet}
              setMessages={setMessages}
              messages={messages}
              chatExpanded={chatExpanded}
              charts={charts}
              problemData={problemData}
              isCustomProblem={isCustomProblem}
            />
          </>

        }
        {showQuiz && <div className="analysis-header">
          <h4 className="analysis-header-title">{assessments?.[0]?.question}</h4>
          <div className="analysis-column-field-container" style={{ marginTop: "15px" }}>
            {assessments?.[0]?.options?.map((option) => {

              return (
                <div className="analysis-column-field-item">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={quizAnswers?.includes(option?.option)}
                      onChange={(e) => {
                        if (e?.target?.checked) {
                          setQuizAnswers((prevAnswers) =>
                            prevAnswers.includes(option) ? prevAnswers : [...prevAnswers, option?.option]
                          );
                        } else {
                          setQuizAnswers((prevAnswers) => prevAnswers.filter((x) => x !== option?.option));
                        }

                      }} />
                    <span className="checkmark"></span>
                    <span className="checkbox-label">{option?.option}</span>
                  </label>
                </div>
              )
            })}
          </div>
        </div>}

      </div>





    </>
  );
};

export default DataAnalysis;



