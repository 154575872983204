import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
    getOptionsConfig,
    getDefaultOptionsValues,
} from '@rawgraphs/rawgraphs-core'
import Profile from "../assets/img/Avatar.png";
import Menu from "../assets/img/menu.svg";
import Vector from "../assets/img/progress.svg";
import VectorConfirmed from "../assets/img/progressSuccess.svg";
import ChatIconCollapsed from "../assets/img/collapsed-chat.svg"
import ExclamationIcon from "../assets/img/exclamation.svg";
import TickIcon from "../assets/img/check-circle.svg"
import data from "../assets/img/data.svg";
import link from "../assets/img/link.svg";
// import csv from "../assets/img/csv.svg";
import successIcon from "../assets/img/success.svg";
import Lottie from "lottie-react";
import axios from "axios";
import LoadingLottie from "../assets/animations/B7nKG0EeXW.json"
import LoadingLottieChat from "../assets/animations/datalyze_loader_Black.json"
import { motion } from "framer-motion";
import 'react-tippy/dist/tippy.css';
import "./Gameplay.css"
import DataAnalysis from "./DataAnalysis";
import Avatar from './Avatar'
import CreditCard from "../data/subscriptionsData.json"
import Papa from 'papaparse';
import UploadCsv from "./Tutorials/UploadCsv";
import DataCleanup from "./Tutorials/DataCleanup";
import AnalysisModal from "./Tutorials/AnalysisModal"
import MissingDataFixingModal from "./Tutorials/MissingDataFixingModal";
import TreatmentModal from "./Tutorials/TreatmentModal";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useDispatch, useSelector } from "react-redux";
import ProblemList from "../data/problems";
import {
    setCoinsCount
} from "../store/slices";
import { useGetProblemByIdQuery, useUpdateCoinsMutation } from "../store/services/datalyzeProblems";
import { useNavigate, useParams } from "react-router-dom";
import baseCharts from "./charts/charts";
import useDataLoader from "./hooks/useDataLoader"
import useSafeCustomCharts from './hooks/useSafeCustomCharts';
import DeleteDuplicateModal from "./Modals/DeleteDuplicateModal";
import CustomTypography from "../mui/customTypography";
import CoinsTooltipLg from "./CoinsTooltip/CoinsTooltipLg";
import CoinsTooltipSm from "./CoinsTooltip/CoinsTooltipSm";
import { BtnMain } from "../mui/customButton"
import { Box, Grid2 } from "@mui/material";
import Chatbox from "./Chat/Chatbox";
import AskGPT from "../services/AskGPT";
const tasks = [{ title: "Data Loading", status: "welcome" }, { title: "Data Cleaning", status: "cleanup" }, { title: "Data Analysis", status: "analyse" },];
import { useMediaQuery } from "react-responsive";

const Gameplay = () => {
    const { problemId } = useParams();
    let isCustomProblem = problemId ? false : true;
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const dataMappingRef = useRef(null);
    const jwt = useSelector((state) => state?.user?.jwt);
    const currentProblem = useSelector((state) => state?.user?.value);
    const coinsCount = useSelector((state) => state?.user?.coinsCount);
    const userDetails = useSelector((state) => state?.user?.userDetails);
    const { data: problemData } = useGetProblemByIdQuery({
        location: "app",
        token: jwt,
        id: problemId
    });

    let problemDetails = ProblemList?.[currentProblem]
    const [task, setTask] = useState("welcome");
    const [showQuiz, setShowQuiz] = useState(false);
    const [showWrongAnsModal, setShowWrongAnsModal] = useState(false);
    const [isCheckedHideCleanup, setIsCheckedHideCleanup] = useState(false);
    const [tableData, setTableData] = useState(CreditCard);
    const [showCoinAnimation, setShowCoinAnimation] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [successIndex, setSuccessIndex] = useState(0)
    const speechRef = useRef(null);
    const containerRef = useRef();
    const [expand, setExpand] = useState(true);
    const [showDuplicateDataToast, setShowDuplicateDataToast] = useState(false);
    const [showDeleteToast, setShowDeleteToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [duplicateDataList, setDuplicateDataList] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [userChatInput, setChatUserInput] = useState("")
    const [selectedAction, setSelectedAction] = useState("none");
    const [showActionCreator, setShowActionCreator] = useState(false);
    const [chatExpanded, setChatExpanded] = useState(true);
    const [isAwaitingGptResponse, setIsAwaitingGptResponse] = useState(false);
    //Tutorial animations
    const [showLinkCsvModal, setShowLinkCsvModal] = useState(false);
    const [showDataCleanupModal, setShowDataCleanuModal] = useState(false);
    const [selectedMissingValue, setSelectedMissingValue] = useState({})
    const [cleanupPerformed, setCleanupPerformed] = useState({
        duplicates: false,
        missing_data: false
    });
    const [quizAnswers, setQuizAnswers] = useState([]);
    const [showNoDuplicateModal, setShowNoDuplicateModal] = useState(false);
    const [showNoEmptyModal, setShowNoEmptyModal] = useState(false);
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [showMissingDataModal, setShowMissingDataModal] = useState(false);
    const [speechEnabled, setSpeechEnabled] = useState(true);
    const [showTreatmentModal, setShowTreatmentModal] = useState(false);

    const [updateCoins] = useUpdateCoinsMutation();

    let [messages, setMessages] = useState([
        {
            text: "Hey there!",
            type: "Sensei"
        },
    ]);

    //Charts
    const [showChartSelector, setShowChartSelector] = useState(false);
    const [
        customCharts,
    ] = useSafeCustomCharts()
    const charts = useMemo(() => baseCharts.concat(customCharts), [customCharts]);
    const dataLoader = useDataLoader()
    const {
        userInput,
        setUserInput,
        // resetDataLoader,
        data: dataSet,
    } = dataLoader
    const [mappingLoading, setMappingLoading] = useState(false)
    const [visualOptions, setVisualOptions] = useState(() => {
        const options = getOptionsConfig(charts[0]?.visualOptions)
        return getDefaultOptionsValues(options)
    })
    const [rawViz, setRawViz] = useState(null)
    console.log(userInput, "userInput", dataSet, rawViz, mappingLoading);
    const [currentChart, setCurrentChart] = useState(charts?.[0]);
    const isMobile = useMediaQuery({ query: "(max-width:768px)" });
    // const elevenLabsApiKey = process.env.REACT_APP_ELEVEN_LABS_API_KEY;
    // const voiceId = process.env.REACT_APP_ELEVEN_LABS_VOICE_ID;

    useEffect(() => {
        setCurrentChart(charts?.[problemData?.data?.defaultBarIndex]);
    }, [problemData?.data?.defaultBarIndex])


    useEffect(() => {
        if (messages.length === 1 && problemData?.data?.messages?.welcomeMessage && chatExpanded) {
            setMessages([...messages, {
                text: problemData?.data?.messages?.welcomeMessage,
                type: "Sensei"
            }]);
        }

        // Check if the chat is expanded before attempting to scroll
        if (chatExpanded && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messages, chatExpanded, problemData?.data?.messages?.welcomeMessage]);

    const playCoinsSound = () => {
        const sound = () => {
            const audio = new Audio("/collectcoin.wav");
            audio.play();
        };
        setTimeout(() => {
            sound();
        }, 500);

    }

    const handleSuccessfullyCreditedCoins = (returnedCoinsCount) => {
        if (returnedCoinsCount && returnedCoinsCount > coinsCount) {
            playCoinsSound()
            setShowCoinAnimation(true);
            dispatch(setCoinsCount({
                coinsCount: returnedCoinsCount
            }))
            setTimeout(() => {
                setShowCoinAnimation(false)
            }, 2000)
        }
    }

    useEffect(() => {
        if (showQuiz && !isCustomProblem) {
            setMessages([...messages, {
                text: "Alright, let’s put your visualization skills to the test! Your mission is to create a column graph showing the number of users with each card type across all months.",
                type: "Sensei"
            }])
            setTimeout(() => {
                setMessages([...messages, {
                    text: "This will help us identify monthly trends and figure out what might be going wrong with Platinum cards.Ready ? Let’s dive in!",
                    type: "Sensei"
                }])
            }, 4500)
        }
    }, [showQuiz])


    const clearLocalMapping = useCallback(() => {
        if (dataMappingRef.current) {
            dataMappingRef.current.clearLocalMapping()
        }
    }, [])


    const handleChartChange = useCallback(
        (nextChart) => {
            // setMapping({})
            clearLocalMapping()
            setCurrentChart(nextChart)
            const options = getOptionsConfig(nextChart?.visualOptions)
            setVisualOptions(getDefaultOptionsValues(options))
            setRawViz(null)
        },
        [clearLocalMapping]
    )
    console.log(rawViz, 'rawViz')

    // 11 labs tts ( replace api key and voice-id with an acc that has credits )
    // const playTTS = async (text) => {
    //     try {
    //         const response = await axios.post(`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`,
    //             { text },
    //             {
    //                 headers: {
    //                     "content-type": "application/json",
    //                     "xi-api-key": elevenLabsApiKey,
    //                 },
    //                 responseType: "arraybuffer",
    //             }
    //         );
    //         // Create a blob from the audio data
    //         const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
    //         const audioUrl = URL.createObjectURL(audioBlob);

    //         // Play the audio
    //         speechRef.current = new Audio(audioUrl);
    //         speechRef.current.onplay = () => setIsSpeaking(true);
    //         speechRef.current.onended = () => setIsSpeaking(false);
    //         speechRef.current.play();
    //     } catch (error) {
    //         console.error("Error with ElevenLabs TTS:", error);
    //     }
    // }


    const handleAskGPT = async (userChatInput) => {
        setIsAwaitingGptResponse(true);
        try {
            const result = await AskGPT(userChatInput, tableData);
            setMessages((prevMessages) => [...prevMessages, result?.data]); // Append the response
            setIsAwaitingGptResponse(false);
        } catch (err) {
            setMessages((prevMessages) => [
                ...prevMessages,
                "Consider experimenting with different questions."
            ]); // Append error message
            setIsAwaitingGptResponse(false);
        }
    };

    useEffect(() => {
        if (speechEnabled && messages?.length > 0) {
            const currentMessage = messages[messages.length - 1];
            if (currentMessage) {
                // Cancel any ongoing speech before starting a new one
                window.speechSynthesis.cancel();

                // Create a new SpeechSynthesisUtterance instance
                speechRef.current = new SpeechSynthesisUtterance(currentMessage?.text);
                speechRef.current.onstart = () => setIsSpeaking(true);
                speechRef.current.onend = () => setIsSpeaking(false);

                // Start speaking
                window.speechSynthesis.speak(speechRef.current);
                // playTTS(currentMessage) //uncomment for 11labs tts
            }
        }
    }, [messages?.length, speechEnabled]);

    const handleToggle = () => {
        if (isSpeaking) {
            // Stop speech
            window.speechSynthesis.cancel();
            setIsSpeaking(false);
        } else if (speechEnabled) {
            // Restart speaking the current message
            const currentMessage = messages[messages.length - 1];
            if (currentMessage) {
                speechRef.current = new SpeechSynthesisUtterance(currentMessage);
                speechRef.current.onstart = () => setIsSpeaking(true);
                speechRef.current.onend = () => setIsSpeaking(false);
                window.speechSynthesis.speak(speechRef.current);
            }
        }

        // Toggle the speechEnabled state
        setSpeechEnabled((prev) => !prev);
    };

    // const handleToggle = async () => {  //uncomment this for 11labs tts
    //     if (isSpeaking) {
    //         // Stop the current audio playback
    //         if (speechRef.current) {
    //             speechRef.current.pause();
    //             speechRef.current.currentTime = 0; // Reset playback
    //         }
    //         setIsSpeaking(false);
    //     } else if (speechEnabled) {
    //         // Restart speaking the current message
    //         const currentMessage = messages[messages.length - 1];
    //         if (currentMessage) {
    //             try {
    //                 // Play the message using playTTS
    //                 await playTTS(currentMessage);
    //                 setIsSpeaking(true);
    //             } catch (error) {
    //                 console.error("Error during TTS playback:", error);
    //             }
    //         }
    //     }

    //     // Toggle the speechEnabled state
    //     setSpeechEnabled((prev) => !prev);
    // };


    const findDuplicates = (array) => {
        const duplicates = [];
        const occurrences = new Map();
        // Count occurrences of each object
        array.forEach((obj) => {
            const objString = JSON.stringify(obj); // Convert object to a string
            occurrences.set(objString, (occurrences.get(objString) || 0) + 1);
        });
        // Add objects that appear more than once, including originals
        array.forEach((obj) => {
            const objString = JSON.stringify(obj);
            if (occurrences.get(objString) > 1) {
                duplicates.push(obj);
            }
        });

        return duplicates;
    };

    const getUniqueObjects = () => {
        const uniqueArray = [];
        const seen = new Set();
        tableData?.forEach((obj) => {
            const objString = JSON.stringify(obj);
            if (!seen.has(objString)) {
                seen.add(objString);
                uniqueArray.push(obj);
            }
        });

        return uniqueArray;
    };

    const handleCleanupData = (type) => {
        if (type === "duplicates") {
            if (cleanupPerformed?.duplicates) {
                setMessages([...messages, {
                    text: `All clear—no duplicates here.This dataset is already on the right track!`,
                    type: "Sensei"
                }])
                setShowNoDuplicateModal(true);
                setTimeout(() => {
                    setShowNoDuplicateModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "duplicates": true
                }))
            } else {
                const duplicateData = findDuplicates(tableData);
                if (duplicateData?.length > 0) {
                    setShowDuplicateDataToast(true);
                    setShowDeleteToast(true)
                    setDuplicateDataList(duplicateData);
                    setMessages([...messages, {
                        text: `Aha! Found ${duplicateData?.length} duplicates. Good catch!`,
                        type: "Sensei"
                    }])
                } else {
                    setShowNoDuplicateModal(true);
                    setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                        "duplicates": true
                    }))
                    setMessages([...messages, {
                        text: `All clear—no duplicates here.This dataset is already on the right track!`,
                        type: "Sensei"
                    }])
                    setTimeout(() => {
                        setShowNoDuplicateModal(false);
                    }, 3000)
                }
            }
        } else if (type === "missing_data") {
            if (cleanupPerformed?.missing_data) {
                setMessages([...messages, {
                    text: "Looks like the data is complete.",
                    type: "Sensei"
                }])
                setShowNoEmptyModal(true)
                setTimeout(() => {
                    setShowNoEmptyModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "missing_data": true
                }))
                return;
            }

            setShowDeleteToast(false);
            const incompleteData = tableData?.filter((x) => {
                return Object.values(x).some(value => value === null || value === undefined || value === "");
            });

            if (!incompleteData?.length) {
                setMessages([...messages, {
                    text: "Looks like the data is complete.",
                    type: "Sensei"
                }])
                setShowNoEmptyModal(true)
                setTimeout(() => {
                    setShowNoEmptyModal(false);
                }, 3000)
                setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                    "missing_data": true
                }))
            } else {
                setDuplicateDataList(incompleteData);
                setMessages([...messages, {
                    text: `Oh no, looks like some values are missing! Don’t worry—we’ll fix them in no time.`,
                    type: "Sensei"
                }])
                if (localStorage.getItem("dont-show-missing-value") === "true") {
                    setShowMissingDataModal(true);
                    return;
                } else {
                    setTimeout(() => {
                        setShowTreatmentModal(true);
                    }, 1000);
                }

            }
        } else {
            console.log("reset")
        }
    }

    const fillMissingCategoryValue = (key) => {
        if (selectedMissingValue?.[key] === "mode") {
            const valueCounts = {};
            // Count occurrences of each value for the given key
            for (const obj of tableData) {
                const value = obj[key];
                if (value !== undefined) {
                    valueCounts[value] = (valueCounts[value] || 0) + 1;
                }
            }

            // Find the most occurring value
            let maxCount = 0;
            let mostOccurringValue = null;

            for (const [value, count] of Object.entries(valueCounts)) {
                if (count > maxCount) {
                    maxCount = count;
                    mostOccurringValue = value;
                }
            }

            return mostOccurringValue;
        }
    }

    const fillMissingNumericValue = (key) => {
        if (selectedMissingValue?.[key] === "mean") {
            let sum = 0;
            let count = 0;
            for (const obj of tableData) {
                const value = parseFloat(obj[key]); // Convert the value to a number
                if (!isNaN(value)) {
                    sum += value;
                    count++;
                }
            }

            return count > 0 ? (sum / count).toFixed(2) : null;
        } else if (selectedMissingValue?.[key] === "median") {
            const numericValues = [];
            for (const obj of tableData) {
                const value = parseFloat(obj[key]); // Convert the value to a number
                if (!isNaN(value)) {
                    numericValues.push(value);
                }
            }

            if (numericValues.length === 0) return null;
            numericValues.sort((a, b) => a - b);
            const mid = Math.floor(numericValues.length / 2);
            if (numericValues.length % 2 === 0) {
                return ((numericValues[mid - 1] + numericValues[mid]) / 2).toFixed(2); // Average of middle two numbers
            } else {
                return (numericValues[mid]).toFixed(2); // Middle value
            }
        }
    }



    const handleFixingMissingData = async () => {
        setMessages([...messages, {
            text: "Impressive! You’ve patched up all the gaps. No blanks holding us back now.",
            type: "Sensei"
        }])
        let finalFixedList = tableData?.slice();
        const CompleteDataSet = tableData?.filter((x) => {
            return Object.values(x).every(value => Boolean(value))
        });

        finalFixedList = duplicateDataList?.map((set) => {
            let finalObj = Object.assign({}, set);
            let hasMoreThanOneFieldMissing = Object.keys(finalObj)?.filter((x) => !finalObj?.[x])?.length > 1 ? true : false;
            if (hasMoreThanOneFieldMissing) {
                let listOfAllTheMissingFields = Object.keys(finalObj)?.filter((x) => !finalObj?.[x]);
                listOfAllTheMissingFields.forEach((missingField) => {
                    let dataType = problemDetails?.tableHeaders?.filter((header) => header?.accessor === missingField)?.[0]?.type;
                    finalObj = Object.assign({}, finalObj, {
                        [missingField]: dataType === "string" ? fillMissingCategoryValue(missingField) : fillMissingNumericValue(missingField)
                    })
                })
            } else {
                Object.keys(set)?.forEach((key) => {
                    if (!set?.[key]) {
                        let dataType = problemDetails?.tableHeaders?.filter((header) => header?.accessor === key)?.[0]?.type;
                        if (dataType === "string") {
                            finalObj = Object.assign({}, set, {
                                [key]: fillMissingCategoryValue(key)
                            })
                        } else {
                            finalObj = Object.assign({}, set, {
                                [key]: Number(fillMissingNumericValue(key))
                            })
                        }
                    }
                })
            }

            return finalObj;
        })

        let allFieldsToBeDeleted = [];
        Object.keys(selectedMissingValue)?.forEach((key) => {
            if (selectedMissingValue?.[key] === "delete") {
                allFieldsToBeDeleted.push(key);
            }
        });

        if (allFieldsToBeDeleted?.length > 0) {
            allFieldsToBeDeleted?.forEach((keyToBeDeleted) => {
                finalFixedList = finalFixedList?.filter((x) => x?.[keyToBeDeleted])
            })
        }

        setTableData([...(finalFixedList?.length > 0 ? finalFixedList : []), ...(CompleteDataSet?.length > 0 ? CompleteDataSet : [])]);
        setDuplicateDataList([]);
        setCleanupPerformed(Object.assign({}, cleanupPerformed, {
            "missing_data": true
        }))


        const { data } = await updateCoins({
            body: {
                "problemId": problemId,
                "action": "data-loading",
            },
            token: jwt
        });
        handleSuccessfullyCreditedCoins(data?.data?.totalScore);
        setShowMissingDataModal(false)
    }


    const handleGoBackToProgress = (status) => {
        setTask(status)
        setShowQuiz(false);
    }


    const fetchDataFromS3 = async (url) => {
        try {
            const response = await axios.get(url);
            const data = response.data;
            return data;
        } catch (error) {
            console.error('Error fetching or parsing data: here', error);
            return null;
        }
    };

    const handleLinkData = async () => {
        setTask("linkingData");
        setExpand(false);


        // setShowCoinAnimation(true)
        try {
            const response = await fetchDataFromS3(problemData?.data?.dataSource);
            const csvData = Papa.unparse(response);
            setUserInput(csvData);
            setTableData(response);
            setTask("cleanup")
            setMessages([...messages, {
                text: "Ah, there it is! The data has arrived safely. Let’s have a quick look to see what we’re working with.",
                type: "Sensei"
            }])

            const { data } = await updateCoins({
                body: {
                    "problemId": problemId,
                    "action": "data-loading",
                },
                token: jwt
            });


            handleSuccessfullyCreditedCoins(data?.data?.totalScore);
            setTimeout(() => {
                setShowCoinAnimation(false)
                const duplicateData = findDuplicates(tableData);
                if (duplicateData?.length > 0) {
                    setShowDuplicateDataToast(true);
                }
                setSuccessIndex(1)
            }, 2000)

        } catch (error) {
            console.error("Error loading JSON file:", error);
        }
    }

    const areArraysEqual = (arr1, arr2) => {
        // Check if lengths are equal
        if (arr1.length !== arr2.length) return false;
        // Check if every element matches
        console.log(arr1, 'arr1', arr2, arr1.every((value) => arr2?.includes(value)))
        return arr1.every((value) => arr2?.includes(value));
    }

    const containerClass = task === "linkingData" ? 'center-content' : 'default-content';

    return (
        <div
            className="wrapper"
        >
            <motion.div
                className="progress-holder"
                animate={{
                    width: expand ? isMobile ? "200px" : "303px" : "80px",
                    opacity: expand ? 1 : 0.8,
                }}
                transition={{
                    type: "spring",
                    stiffness: 150, // Adjust for spring speed
                    damping: 14,    // Adjust for smoothness
                }}
            >
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <motion.div
                        initial={{ opacity: 0, x: -30 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        style={{
                            display: "flex",
                            flexDirection: expand ? "row" : "column",
                            alignItems: "center",
                        }}
                    >
                        {!expand &&
                            <img
                                src={Menu}
                                onClick={() => {
                                    setExpand((prev) => !prev)
                                }}
                                className="burger-menu-collapsed"
                                style={{ marginBottom: "25px", cursor: "pointer" }}
                            />
                        }
                        <img
                            src={Profile}
                            style={{ width: "48px", height: "48px", ...(expand && { marginRight: isMobile ? "0px" : "20px" }) }}
                        />
                        {expand &&
                            <motion.p
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}
                            >
                                {userDetails?.name}
                            </motion.p>}
                    </motion.div>
                    {expand && <motion.div
                        initial={{ opacity: 0, x: -30 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.6, duration: 0.5 }}
                    >
                        <img className="burger-menu-expanded" style={{ cursor: 'pointer' }} src={Menu} onClick={() => {
                            setExpand((prev) => !prev)
                        }} />
                    </motion.div>}
                </motion.div>

                {
                    expand ? <div className="progress-tooltip" style={{ marginTop: "20px" }}>
                        <p
                            className="task-text"
                        >
                            TASKS
                        </p>

                        {tasks.map((item, index) => (
                            <motion.div
                                key={task}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{
                                    delay: index * 0.2,
                                    duration: 0.5,
                                    ease: "easeOut",
                                }}
                                className="taskDetailItemExpanded"
                            >
                                <img src={index + 1 <= successIndex ? VectorConfirmed : Vector} style={{ width: "18px", height: "18px", marginRight: "10px" }} />
                                <p style={{ fontSize: "12px", fontWeight: 600, color: "#E8E9E9", letterSpacing: "0.15px" }}>
                                    {item?.title}
                                </p>
                            </motion.div>
                        ))}

                    </div>
                        : <div className="progress-tooltip" style={{ marginTop: "20px", background: "none" }}>
                            {tasks.map((item, index) => (
                                <div
                                    className="taskDetailItemUnexpanded"
                                    key={index} // Use index as key
                                    id={`tooltip-${index}`} // Unique ID for each tooltip
                                    onClick={() => {
                                        if (index + 1 <= successIndex) {
                                            handleGoBackToProgress(item?.status)
                                        }
                                    }}
                                >
                                    <img
                                        src={index + 1 <= successIndex ? VectorConfirmed : Vector}
                                        style={{ width: "18px", height: "18px", marginRight: "10px" }}
                                        alt={`Step ${index + 1}`}
                                    />
                                    <Tooltip
                                        anchorId={`tooltip-${index}`} // Match the ID of this element
                                        content={item?.title || `Task ${index + 1}`} // Tooltip content
                                        place="top"
                                        offset={10}
                                        className="react-tooltip"
                                        style={{ fontSize: "12px", zIndex: 999 }}
                                    />
                                </div>
                            ))}
                        </div>}
            </motion.div >

            <Box sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                width: "100%",
                gap: 2,
            }}>
                <CoinsTooltipSm
                    showCoinAnimation={showCoinAnimation}
                    coinsCount={coinsCount}
                />
                <Grid2
                    item
                    className={`data-container-${containerClass}`}>
                    <motion.div
                        animate={{
                            width: "100%",
                        }}
                        transition={{
                            duration: 0.4, // Adjust for smoothness
                            ease: [0.25, 0.8, 0.25, 1]
                        }}
                    >

                        {task === "welcome" &&
                            <>

                                <Grid2 sx={{ display: "flex", flexDirection: "column", height: "300px", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <motion.h1
                                        className="problemTitleText"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, ease: "easeOut" }}
                                    >

                                        {problemDetails?.welcomeTitle}
                                    </motion.h1>
                                    <motion.h1
                                        className="problemSubTitleText"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
                                    >
                                        {problemData?.data?.title}
                                    </motion.h1>

                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
                                    >
                                        <CustomTypography
                                            sx={{
                                                fontSize: { md: "1rem" },
                                                fontWeight: 500,
                                                lineHeight: "24px",
                                                color: "#8E8C91",
                                                marginTop: "8px",
                                                textAlign: "center",
                                            }}>
                                            {problemData?.data?.description}
                                        </CustomTypography>
                                    </motion.div>
                                </Grid2>

                                <div className="data-img">
                                    <img src={data} style={{ width: "100%", height: "360px", padding: "1rem" }}></img>
                                </div>
                            </>
                        }

                        {task === "linkingData" &&
                            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
                                <Lottie
                                    animationData={LoadingLottie}
                                    loop={true}
                                    className="loaderStyles"
                                />
                                <h1
                                    className="linkingText"
                                >
                                    We are linking your data
                                </h1>
                                <p
                                    className="linkingSubText"
                                >
                                    This may take some time
                                </p>


                            </div>}
                        {(task === "analyse" || task === "cleanup" || task === "proceed" || task === "summary") &&
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: task === "linked" ? "center" : "", padding: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "5px", marginTop: task === "linked" ? "0px" : "10px", width: "100%" }}>
                                    <motion.h2
                                        style={{
                                            fontSize: (task === "linked" || task === "cleanup" || task === "proceed") ? "28px" : "24px",
                                            fontWeight: 500,
                                            lineHeight: "24px",
                                            color: "var(--Neutral-100, #FFF)",
                                        }}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: 0.5, duration: 1 }}
                                    >
                                        {(task === "proceed" || task === "cleanup") && <motion.img
                                            src={successIcon}
                                            style={{ marginRight: "15px" }}
                                            initial={{ opacity: 0, x: -50 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{ duration: 1 }}
                                        />}
                                        {task === "linked" ? "Data Cleaning" : (task === "cleanup" && showDuplicateDataToast) ? "Data Cleaning" : task === "proceed" ? "Proceed with Summarizing data" : task === "summary" ? "Generated Summary of Subscription Analysis" : !showQuiz && "Choose a Chart"}
                                    </motion.h2>

                                    {task === "analyse" && !showQuiz && !showChartSelector && <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div
                                            className="back-btn"
                                            onClick={() => {
                                                setShowChartSelector(true);
                                            }}
                                        >
                                            {"<"}
                                        </div>

                                    </div>}
                                </div>
                                <Grid2 sx={{ display: "flex", flexDirection: "column", width: "100%", height: "auto", }}>
                                    <MissingDataFixingModal
                                        missingValueList={duplicateDataList}
                                        allData={tableData}
                                        selectedMissingValue={selectedMissingValue}
                                        setSelectedMissingValue={setSelectedMissingValue}
                                        onSubmit={handleFixingMissingData}
                                        open={showMissingDataModal}
                                        dataSet={dataSet}
                                    />

                                    <DataAnalysis
                                        task={task}
                                        tableData={duplicateDataList?.length > 0 ? duplicateDataList : tableData}
                                        setTableData={setTableData}
                                        showDeleteToast={showDeleteToast}
                                        setShowDuplicateDataToast={setShowDuplicateDataToast}
                                        selectedAction={selectedAction}
                                        setSelectedAction={setSelectedAction}
                                        handleCleanupData={handleCleanupData}
                                        showQuiz={showQuiz}
                                        quizAnswers={quizAnswers}
                                        setQuizAnswers={setQuizAnswers}
                                        showChartSelector={showChartSelector}
                                        setShowChartSelector={setShowChartSelector}
                                        setShowAnalysis={setShowAnalysis}
                                        charts={charts}
                                        currentChart={currentChart}
                                        dataMappingRef={dataMappingRef}
                                        dataSet={dataSet}
                                        visualOptions={visualOptions}
                                        setVisualOptions={setVisualOptions}
                                        setRawViz={setRawViz}
                                        setMappingLoading={setMappingLoading}
                                        handleChartChange={handleChartChange}
                                        showActionCreator={showActionCreator}
                                        setMessages={setMessages}
                                        messages={messages}
                                        chatExpanded={chatExpanded}
                                        assessments={problemData?.data?.assessments}
                                        problemData={problemData?.data}
                                        isCustomProblem={isCustomProblem}
                                    />

                                </Grid2>

                            </div>
                        }
                        <div style={{ marginTop: "20px", display: "flex", justifyContent: "end", position: "absolute", width: "100%", bottom: -40 }}>
                            {messages?.length <= 2 ?
                                <div
                                    className="footer-proceed-btn-container"
                                    style={{
                                        position: "absolute",
                                        bottom: 50,
                                        left: 0,
                                    }}
                                >

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                    }}>
                                        <motion.div
                                            className="footer-proceed-btn"
                                            animate={{
                                                background: [
                                                    "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                                    "linear-gradient(91deg, #00E5FF 7.38%, #00FF85 96.85%)",
                                                    "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                                ],
                                            }}
                                            transition={{
                                                duration: 2, // Total time for one cycle
                                                ease: "linear",
                                                repeat: Infinity, // Loop the animation infinitely
                                            }}
                                            whileHover={{
                                                scale: 1.1,
                                                transition: { duration: 0.3 },
                                            }}
                                            onClick={() => {
                                                setMessages([
                                                    ...messages,
                                                    {
                                                        text: "First, load the dataset.",
                                                        type: "Sensei"
                                                    }
                                                ]);
                                                if (localStorage.getItem('dont-show-upload') === "true") {
                                                    return
                                                } else {
                                                    setTimeout(() => {
                                                        setShowLinkCsvModal(true);
                                                    }, 500)
                                                }
                                            }}
                                        >
                                            Proceed
                                        </motion.div>

                                    </div>
                                </div> : task === "welcome" ?
                                    <div
                                        className="action-btn-wrapper">
                                        {currentProblem !== "Custom Problem" &&
                                            <>
                                                <motion.div
                                                    whileHover={{
                                                        scale: 1.05,
                                                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                                                        transition: { duration: 0.3 },
                                                    }}
                                                >
                                                    <BtnMain onClick={() => {
                                                        handleLinkData()
                                                    }}> Link Data
                                                        <img src={link} style={{ marginLeft: "8px" }} />
                                                    </BtnMain>
                                                </motion.div>

                                                {/* <p style={{ color: "#fff", marginLeft: "7px", marginRight: "7px" }}>OR</p> */}
                                            </>
                                        }
                                        {/* <motion.div
                                            whileHover={{
                                                scale: 1.05,
                                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                                                transition: { duration: 0.3 },
                                            }}
                                        >
                                            <BtnMain onClick={() => document.getElementById('csvUpload').click()}>Upload CSV
                                                <img src={csv} style={{ marginLeft: "8px" }} />
                                            </BtnMain>
                                            <input
                                                id="csvUpload"
                                                type="file"
                                                accept=".csv"
                                                style={{ display: 'none' }}
                                                onChange={async (e) => {
                                                    resetDataLoader();
                                                    const file = e.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', (e) => {

                                                        setUserInput(e.target.result)
                                                    })
                                                    reader.readAsText(file);
                                                    if (file) {
                                                        Papa.parse(file, {
                                                            header: true,
                                                            complete: (results) => {
                                                                setTableData(results.data);
                                                            },
                                                            error: (error) => console.error(error),
                                                        });
                                                    }
                                                    setShowDataCleanuModal(false);
                                                    setTask("linkingData");
                                                    setExpand(false)
                                                    setTimeout(() => {
                                                        setTask("cleanup")
                                                        setMessages([...messages, {
                                                            text: "Ah, there it is! The data has arrived safely. Let’s have a quick look to see what we’re working with.",
                                                            type: "Sensei"
                                                        }])
                                                    }, 2000);

                                                    const { data } = await updateCoins({
                                                        body: {
                                                            "problemId": problemId,
                                                            "action": "data-loading",
                                                        },
                                                        token: jwt
                                                    });

                                                    handleSuccessfullyCreditedCoins(data?.data?.totalScore)
                                                    setTimeout(() => {
                                                        setShowCoinAnimation(false)
                                                        const duplicateData = findDuplicates(tableData);
                                                        if (duplicateData?.length > 0) {
                                                            setShowDuplicateDataToast(true);
                                                        }
                                                        setSuccessIndex(1)
                                                    }, 2000)
                                                }}
                                            />
                                        </motion.div> */}
                                    </div> : null}
                            {(task === "cleanup" || task === "proceed" || task === "analyse") && !showChartSelector &&
                                <div
                                    className="go-back-btn-container"
                                    style={{
                                        position: "absolute",
                                        bottom: 50,
                                        left: 0,
                                    }}
                                >

                                    {showQuiz && <button
                                        onClick={() => {
                                            setShowQuiz(false);
                                        }}
                                        className="go-back-btn"
                                    >
                                        Go Back
                                    </button>}
                                    <button
                                        className="proceed-btn"
                                        onClick={async () => {
                                            if (task === "analyse" && !showQuiz) {
                                                if (!isCustomProblem) {
                                                    setShowQuiz(true);
                                                    setSuccessIndex(3);
                                                } else {
                                                    return;
                                                }
                                            } else if (task === "analyse" && showQuiz) {
                                                if (areArraysEqual(quizAnswers, problemData?.data?.assessments?.[0]?.options?.filter((x) => x?.isCorrect)?.map(y => y?.option))) {
                                                    if (problemData?.data?.messages?.correctAnswerMessage) {
                                                        setMessages([...messages, {
                                                            text: problemData?.data?.messages?.correctAnswerMessage,
                                                            type: "Sensei"
                                                        }]);

                                                        setShowWrongAnsModal(false)
                                                        setSuccessIndex(4);
                                                        const { data } = await updateCoins({
                                                            body: {
                                                                "problemId": problemId,
                                                                "action": "assessment",
                                                                "hasCompleted": true
                                                            },
                                                            token: jwt
                                                        });
                                                        handleSuccessfullyCreditedCoins(data?.data?.totalScore);
                                                        setTimeout(() => {
                                                            navigate('/gamelist');
                                                        }, 10000);
                                                    }
                                                } else {
                                                    setMessages([...messages, {
                                                        text: "Hmm, not quite. Looks like there’s more to uncover in the data. Take a step back and dig a little deeper into those trends. You’ve got this!",
                                                        type: "Sensei"
                                                    }]);
                                                    // (true);
                                                    setShowWrongAnsModal(true);
                                                    setTimeout(() => {
                                                        setShowWrongAnsModal(false);
                                                    }, [4000]);
                                                }

                                            } else {
                                                if (cleanupPerformed?.duplicates && cleanupPerformed?.missing_data) {
                                                    console.log("Cleanup Done")
                                                    setShowChartSelector(true);
                                                    setTask("analyse");
                                                    setSuccessIndex(2);

                                                    const { data } = await updateCoins({
                                                        body: {
                                                            "problemId": problemId,
                                                            "action": "data-analysis",
                                                        },
                                                        token: jwt
                                                    });
                                                    handleSuccessfullyCreditedCoins(data?.data?.totalScore);
                                                    if (problemData?.data?.messages?.chartMessage1 && problemData?.data?.messages?.chartMessage2) {
                                                        setMessages(prevMessages => [
                                                            ...prevMessages,
                                                            {
                                                                text: problemData?.data?.messages?.chartMessage1,
                                                                type: "Sensei"
                                                            }
                                                        ]);
                                                        setTimeout(() => {
                                                            setMessages(prevMessages => [
                                                                ...prevMessages,
                                                                {
                                                                    text: problemData?.data?.messages?.chartMessage2,
                                                                    type: "Sensei"
                                                                }
                                                            ]);
                                                        }, 7000);
                                                    }
                                                } else {
                                                    if (localStorage.getItem('dont-show-cleanup') === "true") {
                                                        setShowActionCreator(true);
                                                        setShowDataCleanuModal(false);
                                                        setMessages([...messages, {
                                                            text: "Hmm, let’s see if we’ve got any pesky duplicates lurking around.",
                                                            type: "Sensei"
                                                        }])
                                                        if (cleanupPerformed?.duplicates && cleanupPerformed?.missing_data) {
                                                            setShowChartSelector(true);
                                                            setTask("analyse");
                                                            setSuccessIndex(2);
                                                        } else if (!cleanupPerformed?.duplicates) {
                                                            setSelectedAction("duplicates")
                                                            handleCleanupData("duplicates")
                                                        } else if (!cleanupPerformed?.missing_data) {
                                                            setSelectedAction("missing_data")
                                                            handleCleanupData("missing_data")

                                                        }

                                                        return;
                                                    } else {
                                                        setShowDataCleanuModal(true);
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        {showQuiz ? "Submit" : "Proceed"}
                                    </button>
                                </div>
                            }
                        </div>
                    </motion.div >
                </Grid2>

                <div
                    className={`spring-effect ${chatExpanded ? "expanded" : "collapsed"}`}
                    style={{ width: chatExpanded ? isMobile ? "100%" : "45%" : "80px", display: 'flex', flexDirection: "column", marginLeft: "15px", }}>
                    {!chatExpanded ?
                        <div style={{ display: "flex", flexDirection: "column", height: "90vh", justifyContent: "center" }}>
                            <div className='chat-minimized-view' onClick={() => {
                                setChatExpanded((prev) => !prev)
                            }}>
                                {isAwaitingGptResponse ? <Lottie
                                    animationData={LoadingLottieChat}
                                    loop={true}
                                    className="chatLoaderStyles"
                                /> :
                                    <img src={ChatIconCollapsed} alt="ChatIconCollapsed" />
                                }
                            </div>
                        </div>
                        :
                        <>
                            <CoinsTooltipLg
                                showCoinAnimation={showCoinAnimation}
                                chatExpanded={chatExpanded}
                                coinsCount={coinsCount}
                            />

                            <Chatbox
                                containerRef={containerRef}
                                messages={messages}
                                showLinkCsvModal={showLinkCsvModal}
                                showDataCleanupModal={showDataCleanupModal}
                                showAnalysis={showAnalysis}
                                userChatInput={userChatInput}
                                onFocusHandler={() => {
                                    if (showTooltip) {
                                        setShowTooltip(false);
                                    }
                                }}
                                onChangeHandler={(text) => {
                                    setChatUserInput(text)
                                }}
                                onKeyDownHandler={(e) => {
                                    if (e.key === "Enter" && userChatInput.trim()) {
                                        setMessages((prevMessages) => [...prevMessages, {
                                            text: userChatInput,
                                            type: "You"
                                        }]); // Immediately add the input to the messages
                                        setChatUserInput(""); // Clear the input field
                                        handleAskGPT(userChatInput);
                                    }
                                }}
                                task={task}
                                chatExpanded={chatExpanded}
                                setChatExpanded={setChatExpanded}
                                handleToggleChat={() => {
                                    setChatExpanded((prev) => !prev)
                                }}
                                isAwaitingGptResponse={isAwaitingGptResponse}
                            />
                        </>
                    }

                    <div className="avatar-tooltip" onClick={handleToggle}>
                        <Avatar isSpeaking={isSpeaking} />
                    </div>

                </div>
            </Box >
            {
                showNoDuplicateModal && <motion.div
                    className="no-duplicate-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    <img src={ExclamationIcon} style={{ marginRight: "5px" }} />
                    No Duplicates found

                </motion.div>
            }
            {
                showNoEmptyModal && <motion.div
                    className="no-duplicate-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    <img src={ExclamationIcon} style={{ marginRight: "5px" }} />
                    No Missing Values found

                </motion.div>
            }

            <DeleteDuplicateModal
                onClickHandler={() => {
                    setShowDeleteToast(false);
                    const uniqueData = getUniqueObjects();
                    setTableData(uniqueData);
                    setShowSuccessToast(true)
                    setTimeout(() => {
                        setShowSuccessToast(false)
                    }, 2000)
                    setDuplicateDataList([])
                    setCleanupPerformed(Object.assign({}, cleanupPerformed, {
                        "duplicates": true,
                    }))
                    setMessages([...messages, {
                        text: "Great work! You just trimmed the excess. The data looks neater now.",
                        type: "Sensei"
                    }])
                }}
                showDeleteToast={showDeleteToast}
            />
            {
                showWrongAnsModal && <motion.div
                    className="duplicate-dataset-toast"
                    style={{ width: "470px" }}
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    That wasn’t quite right. Try again.
                    <button className="action-btn" onClick={() => {
                        setShowQuiz(false)
                        setShowWrongAnsModal(false);
                        setQuizAnswers([])
                    }}>
                        Go Back
                    </button>
                </motion.div>
            }
            {
                showSuccessToast && task === "cleanup" && <motion.div
                    className="success-dataset-toast"
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 50, damping: 15 }}
                >
                    Duplicate data Successfully deleted.
                    <img src={TickIcon} />
                </motion.div>
            }

            <UploadCsv
                setShowLinkCsvModal={setShowLinkCsvModal}
                open={showLinkCsvModal}
                setMessages={setMessages}
                messages={messages}
            />

            <DataCleanup
                open={showDataCleanupModal}
                handleClose={() => {
                    setShowActionCreator(true);
                    setShowDataCleanuModal(false);
                    setMessages([...messages, {
                        text: "Hmm, let’s see if we’ve got any pesky duplicates lurking around.",
                        type: "Sensei"
                    }])
                    if (cleanupPerformed?.duplicates && cleanupPerformed?.missing_data) {
                        setShowChartSelector(true);
                        setTask("analyse");
                        setSuccessIndex(2);
                    } else if (!cleanupPerformed?.duplicates) {
                        setSelectedAction("duplicates")
                        handleCleanupData("duplicates")
                    } else if (!cleanupPerformed?.missing_data) {
                        setSelectedAction("missing_data")
                        handleCleanupData("missing_data")

                    }
                    if (isCheckedHideCleanup) {
                        localStorage.setItem('dont-show-cleanup', 'true');
                        setIsCheckedHideCleanup(false);
                    }
                }}


                isCheckedHideCleanup={isCheckedHideCleanup}
                setIsCheckedHideCleanup={setIsCheckedHideCleanup}
            />
            <AnalysisModal
                open={showAnalysis}
                handleClose={() => {
                    setShowActionCreator(true);
                    setShowAnalysis(false);
                }}
            />
            <TreatmentModal
                open={showTreatmentModal}
                setShowTreatmentModal={setShowTreatmentModal}
                setShowMissingDataModal={setShowMissingDataModal}
            />
        </div >
    );
};

export default Gameplay;
