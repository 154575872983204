import React from "react";
import { Button } from "@mui/material";

const BtnInfo = ({ children, onClick,sx, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        display: "flex",
        height: "60px",
        padding: "15px 15px",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
        flexShrink: 0,
        borderRadius: "37.037px",
        border: "0.741px solid #868686",
        textAlign: "center",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontSize: "28px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        background: "linear-gradient(91deg, #01fe87 7.61%, #01e6f9 76.3%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        cursor: "default",
        textTransform: "none",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default BtnInfo;
