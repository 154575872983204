import axios from 'axios';
const AskGPT = async (searchProp, csvData) => {
    const LLMSBackend = "https://api.dev.llms.machinehack.com";
    try {
        const params = new URLSearchParams();
        // params.append("s3_link", csvLink);
        params.append("data_array", JSON.stringify(csvData));
        params.append(
            "question",
            searchProp
        );
        params.append("temperature", 0);
        params.append("top_p", 1);
        params.append("max_tokens", 500);
        const response = await axios.post(
            `${LLMSBackend}/agents/v1/csv/`,
            params
        );

        return response;
    } catch (err) {
        let errorMessage = "Consider experimenting with different questions.";
        throw new Error(errorMessage);

    }
}

export default AskGPT;