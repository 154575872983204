import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setJwtToken, setUserDetails, setCoinsCount } from "../slices";


export const userAuthApis = createApi({
    reducerPath: "userAuthApis",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://murhsmozuf.execute-api.ap-south-1.amazonaws.com/dev/",
    }),
    refetchOnFocus: true,
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (newPost) => ({
                url: "signin",
                method: "POST",
                body: newPost.body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled; // Wait for the query to resolve
                    if (result?.data?.data?.token) {
                        dispatch(setJwtToken(result?.data?.data?.token)); // Set the token (assuming result.data contains token)
                    }
                } catch (error) {
                    console.log(error, "error");
                }
            },
        }),
        signupUser: builder.mutation({
            query: (newPost) => ({
                url: "signup",
                method: "POST",
                body: newPost.body,
            }),
        }),
        getUserDetails: builder.query({
            query: (payload) => ({
                url: `fetch-user`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload?.token}`,
                },
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled;
                    if (result?.data?.data) {
                        dispatch(setUserDetails(result.data.data));
                        dispatch(setCoinsCount({ coinsCount: result.data.data?.totalScore }))
                    }
                } catch (error) {
                    console.log(error, "error");
                }
            },
        }),
    }),

});

export const { useLoginUserMutation, useLazyGetUserDetailsQuery, useSignupUserMutation } = userAuthApis;