import React from 'react'
import InfoImage from "../assets/img/imageRight.png"
import LearningImage from "../assets/img/imageLeft.png"
import CubeImage from "../assets/img/Cube.png"
import InterfaceImg from "../assets/img/interface.svg"
import WorkflowImg from "../assets/img/workflow.svg"
import Access from "../assets/img/access.svg"
import Hero from "../assets/img/Element 2.png"
import bg from "../assets/img/header-bg.png"
import "./Landing.css"
import { BtnMain, BtnSecondary, BtnMainLanding } from '../mui/customButton';
import CustomTypography from '../mui/customTypography'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';
import BtnInfo from '../mui/btnInfo';
import { Card, CardContent, CardMedia, Box, Grid } from "@mui/material";
import { useSelector } from 'react-redux'

const Landing = () => {
    const jwt = useSelector((state) => state?.user?.jwt);
    const navigate = useNavigate();
    const items = [
        { text: "Combine datasets and handle missing values.", delay: 0.1 },
        { text: "Perform basic statistical analysis.", delay: 0.3 },
        { text: "Visualize data through interactive charts and graphs.", delay: 0.5 },
        { text: "Export clean and analyzed data for reports or further use.", delay: 0.7 },
    ];
    const features = [
        {
            img: InterfaceImg,
            title: "No-Code Interface",
            description: "Designed for simplicity, no technical skills needed.",
            delay: 0.2,
        },
        {
            img: WorkflowImg,
            title: "Guided Workflows",
            description: "Step-by-step assistance from data loading to analysis.",
            delay: 0.4,
        },
        {
            img: Access,
            title: "Accessible to All",
            description: "Affordable, beginner-friendly, and cloud-based.",
            delay: 0.6,
        },
    ];

    return (
        <div className='landing-wrapper'>
            <div className='background-header'>
                <img src={bg} className='background-image'></img>
            </div>
            <motion.div
                className="landing-header"
                initial={{ opacity: 0, y: 50 }}  // Initially hidden and moved down
                whileInView={{
                    opacity: 1,  // Fade in
                    y: 0,        // Move to original position
                }}
                transition={{
                    type: "spring",
                    stiffness: 200,
                    damping: 25,
                    duration: 1,
                    delay: 0.3,  // Add delay to the secondary text for a staggered effect
                }}
                viewport={{ once: true, amount: 0.5 }}
            >
                <CustomTypography
                    sx={{
                        textAlign: "center",
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                        fontSize: { xs: "2rem", sm: "3rem", md: "4.25rem" },
                        fontWeight: 700,
                        lineHeight: "normal",
                        background: "linear-gradient(91deg, #01fe87 7.61%, #01e6f9 76.3%)",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                    }}
                >
                    Transform Data, Unleash Insights <br />
                    No Expertise Needed!
                </CustomTypography>
            </motion.div>
            <CustomTypography
                sx={{
                    color: "#868686",
                    textAlign: "center",
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    fontSize: "1.125rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    maxWidth: "663px",
                    margin: "0 auto",
                }}
            >
                Datalyze empowers you to explore, clean, and analyze data effortlessly,
                whether you&apos;re a beginner or just curious about data.
            </CustomTypography>

            <div className='action-btn-wrapper-landing' style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                <motion.div
                    className="btn-main"
                    initial={{ x: -200, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{
                        duration: 1,
                        // delay: 1,
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <BtnMainLanding onClick={() => {
                        if (jwt) {
                            navigate(`/gamelist`)
                        }
                        else {
                            navigate("/login")
                        }
                    }}>
                        Get Started For Free
                    </BtnMainLanding>
                </motion.div>


                <motion.div
                    className="btn-secondary"
                    initial={{ x: 200, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{
                        duration: 1,
                        // delay: 1,
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <BtnSecondary onClick={() => navigate("/introduction")}>
                        See How It Works
                    </BtnSecondary>
                </motion.div>
            </div>

            <div className="hero-section">
                <div style={{ margin: "10px auto" }} className='hero-img'>
                    <img src={Hero} />
                </div>

                <div className="hero-section-info">
                    {/* Hero Text Animation */}
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}  // Start with opacity 0 and slightly below the final position
                        animate={{ opacity: 1, y: 0 }}   // Fade in and move to its final position
                        transition={{
                            type: "spring",
                            stiffness: 300,  // Higher stiffness for faster, more rigid movement
                            damping: 15,     // Lower damping for a more bouncy effect
                            duration: 1,     // Optional, but controls the duration for smoothness
                        }}
                        viewport={{ once: true, amount: 0.5 }}  // Trigger animation when 50% of the element is in view
                    >
                        <CustomTypography
                            sx={{
                                color: "#fff",
                                textAlign: "center",
                                fontFamily: "'Plus Jakarta Sans', sans-serif",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                            }}
                        >Your Data Journey Starts Here</CustomTypography>
                    </motion.div>

                    {/* Button Animation */}
                    <BtnInfo
                        style={{ maxWidth: "350px", marginTop: "30px" }}
                    >
                        What is Datalyze?
                    </BtnInfo>

                    {/* Paragraph Animation */}
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}  // Start invisible and slightly below the final position
                        whileInView={{ opacity: 1, y: 0 }}  // Fade in and move to its final position
                        transition={{
                            duration: 1,
                            ease: "easeOut",  // Smooth easing effect
                        }}
                        viewport={{ once: true, amount: 0.7 }}  // Trigger animation once when 50% of the element is in view
                    >
                        <CustomTypography
                            sx={{
                                color: "#868686",
                                textAlign: "center",
                                fontFamily: "'Plus Jakarta Sans', sans-serif",
                                fontSize: {
                                    xs: "0.8rem",
                                    sm: "1.8rem",
                                    md: "2.125rem",
                                },
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                marginTop: { xs: "1px", md: "50px" },
                                padding: 4,
                            }}

                        >
                            Datalyze is a user-friendly platform that simplifies working with data. From loading raw data to deriving actionable insights, we’ve got you covered—no coding, no technical jargon.</CustomTypography>
                    </motion.div>
                </div>
            </div>
            <Grid
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: { xs: "10px", sm: "100px", md: "130px" } }}>
                <div className='line-div'
                    style={{ width: "286px", height: "1px", backgroundColor: "#1F1F24", marginRight: "30px" }}></div>
                <BtnInfo sx={{ marginTop: { xs: "80px", sm: "0px" } }}>Who is it for?</BtnInfo>
                marginT     <div className='line-div'
                    style={{ width: "286px", height: "1px", backgroundColor: "#1F1F24", marginLeft: "30px" }}></div>
            </Grid>
            <div className="datalyse-info-wrapper">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="datalyse-info-container" >
                        {/* First Item */}
                        <motion.div
                            className="datalyse-info-item"
                            initial={{ opacity: 0, x: -200, scale: 0.8 }}
                            whileInView={{ opacity: 1, x: 0, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 25,
                                delay: 0.3,
                            }}
                            viewport={{ once: true, amount: 0.1 }}
                        >
                            <h5 className="header">Beginners in Data Analysis</h5>
                            <p className="text-para">
                                Learn the basics of working with data in an intuitive way.
                            </p>
                        </motion.div>

                        {/* Second Item */}
                        <motion.div
                            className="datalyse-info-item"
                            initial={{ opacity: 0, x: 200, scale: 0.8 }}
                            whileInView={{ opacity: 1, x: 0, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 25,
                                delay: 0.5, // Delay for a more staggered effect
                            }}
                            viewport={{ once: true, amount: 0.7 }}
                        >
                            <h5 className="header">Non-Technical Professionals</h5>
                            <p className="text-para">
                                Explore data insights without relying on IT teams or technical tools.
                            </p>
                        </motion.div>
                    </div>

                    <div className="datalyse-info-container">
                        {/* Third Item */}
                        <motion.div
                            className="datalyse-info-item"
                            initial={{ opacity: 0, y: 100, rotate: -15 }}
                            whileInView={{ opacity: 1, y: 0, rotate: 0 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 20,
                                delay: 0.7, // Delay for a sequential animation
                            }}
                            viewport={{ once: true, amount: 0.7 }}
                        >
                            <h5 className="header">Students</h5>
                            <p className="text-para">
                                Get a head start on data skills with hands-on guidance.
                            </p>
                        </motion.div>

                        {/* Fourth Item */}
                        <motion.div
                            className="datalyse-info-item"
                            initial={{ opacity: 0, y: 100, rotate: 15 }}
                            whileInView={{ opacity: 1, y: 0, rotate: 0 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 20,
                                delay: 0.9, // Delay for sequential effect
                            }}
                            viewport={{ once: true, amount: 0.7 }}
                        >
                            <h5 className="header">Small Business Owners</h5>
                            <p className="text-para">
                                Make data-driven decisions for your business without any prior expertise.
                            </p>
                        </motion.div>
                    </div>
                </div>

                {/* Image with a scaling effect on scroll */}
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 100,
                        damping: 25,
                        delay: 1, // Delayed scaling effect
                    }}
                    viewport={{ once: true, amount: 0.7 }}
                    className='datalyse-info-img'
                >
                    <img
                        src={InfoImage}
                        style={{
                            padding: "5px",
                            width: "250px",
                            height: "auto",
                            objectFit: "cover",
                        }}
                    />
                </motion.div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "130px", }}>
                <div style={{ width: "286px", height: "1px", backgroundColor: "#1F1F24", marginRight: "30px" }}></div>
                <BtnInfo style={{ maxWidth: "348px" }}>What can you learn?</BtnInfo>
                <div style={{ width: "286px", height: "1px", backgroundColor: "#1F1F24", marginLeft: "30px" }}></div>
            </div>


            <div className="datalyse-info-wrapper-2">
                <div className='datalyse-info-img2'>
                    {/* Image with scaling animation */}
                    <motion.img
                        src={LearningImage}
                        style={{
                            width: "250px",
                            height: "auto"
                        }}
                        initial={{ opacity: 0, scale: 0.8 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 100,
                            damping: 25,
                            delay: 1,
                        }}
                        viewport={{ once: true, amount: 0.7 }}
                    />
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="datalyse-info-container">
                        {/* First Item */}
                        <motion.div
                            className="datalyse-info-item"

                            initial={{ opacity: 0, x: -200, scale: 0.8 }}
                            whileInView={{ opacity: 1, x: 0, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 25,
                                delay: 0.3, // Delay to make it sequential
                            }}
                            viewport={{ once: true, amount: 0.3 }}
                        >
                            <h5 className="header">Data Loading</h5>
                            <p className="text-para">
                                Import files from various formats like Excel, CSV, and more.
                            </p>
                        </motion.div>

                        {/* Second Item */}
                        <motion.div
                            className="datalyse-info-item"

                            initial={{ opacity: 0, x: 200, scale: 0.8 }}
                            whileInView={{ opacity: 1, x: 0, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 25,
                                delay: 0.5, // Delay for a more staggered effect
                            }}
                            viewport={{ once: true, amount: 0.7 }}
                        >
                            <h5 className="header">Data Cleaning</h5>
                            <p className="text-para">
                                Fix messy data and make it usable with just a few clicks.
                            </p>
                        </motion.div>
                    </div>

                    <div className="datalyse-info-container">
                        {/* Third Item */}
                        <motion.div
                            className="datalyse-info-item"

                            initial={{ opacity: 0, y: 100, rotate: -15 }}
                            whileInView={{ opacity: 1, y: 0, rotate: 0 }}
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 20,
                                delay: 0.7, // Delay for a sequential animation
                            }}
                            viewport={{ once: true, amount: 0.7 }}
                        >
                            <h5 className="header">Data Analysis</h5>
                            <p className="text-para">
                                Identify patterns, generate charts, and summarize key insights effortlessly.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>


            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "100px", }}>
                <div style={{ width: "286px", height: "1px", backgroundColor: "#1F1F24", marginRight: "30px" }}></div>
                <BtnInfo style={{ maxWidth: "348px" }}>What all can you do?</BtnInfo>
                <div style={{ width: "286px", height: "1px", backgroundColor: "#1F1F24", marginLeft: "30px" }}></div>
            </div>

            <Box sx={{ mt: 8, p: 2, pl: 12, pr: 12 }}>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{ flexWrap: "wrap" }}
                >
                    {items.map((item, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                            key={index}
                            sx={{
                                display: index % 3 === 0 ? "flex" : "block",
                                justifyContent: index % 3 === 0 && index !== 0 ? "center" : "flex-start",
                            }}
                        >
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 50,
                                    rotate: index % 2 === 0 ? -15 : 15,
                                    scale: 0.9,
                                }}
                                whileInView={{ opacity: 1, y: 0, rotate: 0, scale: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 120,
                                    damping: 20,
                                    delay: item.delay,
                                }}
                                viewport={{ once: true, amount: 0.7 }}
                            >
                                <Card
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        p: 2,
                                        borderRadius: "10px",
                                        background:
                                            "linear-gradient(180deg, #1f1f24 -32.77%, #040404 91.6%)",
                                        border: "1px solid #a2a4a7",
                                        boxShadow: "none",
                                        height: "100px",
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={CubeImage}
                                        alt="Cube Icon"
                                        sx={{
                                            width: "52.63px",
                                            height: "52.63px",
                                            mr: 2,
                                        }}
                                    />
                                    <CardContent sx={{ p: 0 }}>
                                        <CustomTypography
                                            sx={{
                                                color: "#fff",
                                                fontSize: "20px",
                                                fontWeight: 500,
                                                lineHeight: "normal",
                                            }}
                                        >
                                            {item.text}
                                        </CustomTypography>
                                    </CardContent>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "65px", }}>
                <div style={{ width: "286px", height: "1px", backgroundColor: "#1F1F24", marginRight: "30px" }}></div>
                <BtnInfo style={{ maxWidth: "278px" }}>Why Datalyze?</BtnInfo>
                <div style={{ width: "286px", height: "1px", backgroundColor: "#1F1F24", marginLeft: "30px" }}></div>
            </div>

            <Box sx={{ m: 2, p: 2, pl: 15, pr: 15 }}>
                <Grid container spacing={3} justifyContent="center" sx={{
                    mt: 2
                }}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <motion.div
                                initial={{ opacity: 0, y: 50, x: index % 2 === 0 ? -50 : 50, scale: 0.8, rotate: index % 2 === 0 ? -10 : 10 }}
                                whileInView={{ opacity: 1, y: 0, x: 0, scale: 1, rotate: 0 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 250,
                                    damping: 30,
                                    delay: feature.delay,
                                }}
                                viewport={{ once: true, amount: 0.5 }}
                            >
                                <Card
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: 315,
                                        width: "100%",
                                        borderRadius: "10px",
                                        background: "linear-gradient(178deg, rgba(35, 31, 32, 0.23) 10.33%, rgba(0, 254, 137, 0.23) 106.1%, rgba(0, 229, 255, 0.23) 276.34%)",
                                        boxShadow: "none",
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={feature.img}
                                        alt={feature.title}
                                        sx={{ width: 121, height: 121 }}
                                    />
                                    <CardContent sx={{ textAlign: "center", p: 0 }}>
                                        <CustomTypography
                                            sx={{
                                                color: "#fff",
                                                fontSize: { sm: "1.2rem", md: "1.35rem", lg: "1.4rem" },
                                                fontWeight: 700,
                                                mt: 3,
                                            }}
                                        >
                                            {feature.title}
                                        </CustomTypography>
                                        <CustomTypography
                                            sx={{
                                                color: "#868686",
                                                fontSize: { sm: "0.8rem", md: "1.125rem", lg: "1.25rem" },
                                                fontWeight: 600,
                                                mt: 1.5,
                                            }}
                                        >
                                            {feature.description}
                                        </CustomTypography>
                                    </CardContent>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Box>


            <div className='footer-section-bg'>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* Footer Primary Text */}
                    <motion.h3
                        className='footer-primary'
                        initial={{ opacity: 0, y: 50 }}  // Initially hidden and moved down
                        whileInView={{
                            opacity: 1,  // Fade in
                            y: 0,        // Move to original position
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 200,  // Controls the speed of the movement
                            damping: 25,     // Controls the bounciness
                            duration: 1,
                        }}
                        viewport={{ once: true, amount: 0.5 }}  // Trigger when 50% of element is in view
                    >
                        Ready to Datalyze?
                    </motion.h3>

                    {/* Footer Secondary Text */}
                    <motion.h3
                        className='footer-secondary'
                        initial={{ opacity: 0, y: 50 }}  // Initially hidden and moved down
                        whileInView={{
                            opacity: 1,  // Fade in
                            y: 0,        // Move to original position
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 25,
                            duration: 1,
                            delay: 0.3,  // Add delay to the secondary text for a staggered effect
                        }}
                        viewport={{ once: true, amount: 0.5 }}  // Trigger when 50% of element is in view
                    >
                        Unlock your data’s potential today.
                    </motion.h3>

                    {/* Footer Button */}
                    <motion.div
                        style={{ margin: "0 auto", marginTop: "50px" }}
                        initial={{ opacity: 0, scale: 0.8 }}  // Start with button scaled down and invisible
                        whileInView={{
                            opacity: 1,  // Fade in
                            scale: 1,    // Scale to normal size
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 250,  // A bit quicker for the button to scale
                            damping: 25,
                            duration: 1,
                            // delay: 0.5,  // Delay to make the button appear after the texts
                        }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        viewport={{ once: true, amount: 0.5 }}  // Trigger when 50% of the element is in view
                    >
                        <BtnMain>Start Analyzing Now!</BtnMain>
                    </motion.div>
                </div>
                <div className='footer-section'>
                    {/* Other footer content can go here */}
                </div>
            </div>
        </div>
    )
}

export default Landing