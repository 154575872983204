import React from 'react'
import { Box, } from "@mui/material";
import Lottie from "lottie-react";
import CoinAnimations from "../../assets/animations/eZPu8zOqAR.json"
import Coins from "../../assets/img/Coins.png";
import { motion } from "framer-motion";
const CoinsTooltipLg = ({ showCoinAnimation, chatExpanded, coinsCount }) => {
    return (
        <Box sx={{
            display: { xs: "none", sm: "none", md: "block" }
        }}>
            <div className="coins-tooltip" >
                {showCoinAnimation ? <Lottie
                    animationData={CoinAnimations}
                    loop={true}
                    className="coinsAnimation"
                /> : <img src={Coins} style={{ width: "108px", height: "108px", position: "absolute", left: 0, top: -40 }} />}
                <h3>
                    <motion.span
                        className="coins-count-text"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, count: [0, coinsCount] }}
                        transition={{ duration: 1, ease: "easeOut", times: [0, 1] }}
                    >
                        {coinsCount}
                    </motion.span>
                    {chatExpanded && <span style={{
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        color: "#8E8C91",

                    }}>Total <br /> Coins Available</span>}
                </h3>

                {chatExpanded && <div
                    className="redeemContainer"
                >
                    <p
                        className="redeemText"
                    >Redeem
                    </p>
                </div>}
            </div>
        </Box>
    )
}

export default CoinsTooltipLg