import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const datalyzeProblemApis = createApi({
    reducerPath: "datalyzeProblemApis",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://2fp766vd6i.execute-api.ap-south-1.amazonaws.com/dev/datalyze/",
    }),
    refetchOnFocus: true,
    endpoints: (builder) => ({
        getAllProblems: builder.query({
            query: (payload) => ({
                url: `problems?location=${payload?.location}`, // Adjust endpoint to match the API
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload.token}`, // Example of adding a JWT token
                    // Add additional headers here
                },
            }),
        }),
        getProblemById: builder.query({
            query: (payload) => ({
                url: `problems/${payload?.id}?location=${payload?.location}`, // Adjust endpoint to match the API
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload?.token}`, // Example of adding a JWT token
                    // Add additional headers here
                },
            }),
        }),
        getLeaderboard: builder.query({
            query: (payload) => ({
                url: `/leaderboard`, // Adjust endpoint to match the API
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload?.token}`, // Example of adding a JWT token
                    // Add additional headers here
                },
            }),
        }),
        updateCoins: builder.mutation({
            query: (payload) => ({
                url: "trackings",
                method: "PATCH",
                body: payload.body,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${payload.token}`, // Adding JWT token to headers
                },
            }),
        }),
    }),
});

export const { useGetAllProblemsQuery, useGetProblemByIdQuery, useUpdateCoinsMutation, useGetLeaderboardQuery } = datalyzeProblemApis;