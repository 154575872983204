import { useMediaQuery } from 'react-responsive';

const useResponsiveState = (chatExpanded, expandStatus) => {
  const isExtraSmall = useMediaQuery({ maxWidth: 576 });
  const isSmall = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMedium = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isLarge = useMediaQuery({ minWidth: 1025, maxWidth: 1200 });

  const screenSize = isExtraSmall
    ? 'extra-small'
    : isSmall
    ? 'small'
    : isMedium
    ? 'medium'
    : isLarge
    ? 'large'
    : 'extra-large';

  const baseDimensions = {
    'extra-small': { width: 200 },
    'small': { width: 300 },
    'medium': { width: 550 },
    'large': { width: 700 },
    'extra-large': { width: 1071},
  };

  const expandedHeight = 200; // Height when expandStatus is true
  const collapsedHeight = 550; // Height when expandStatus is false

  // Define width adjustment for chatExpanded
  const widthAdjustment = {
    'extra-small': chatExpanded ? 0 : 0, // Adjust width for expanded chat
    'small': chatExpanded ? 0 : 0,
    'medium': chatExpanded ? 0 : 0,
    'large': chatExpanded ? -80 : 0,
    'extra-large': chatExpanded ? -250 : 0,
  };

  // Compute final width and height
  const finalWidth = baseDimensions[screenSize].width + widthAdjustment[screenSize];
  const finalHeight = expandStatus ? expandedHeight : collapsedHeight;

  return { screenSize, dimensions: { width: finalWidth, height: finalHeight } };
};

export default useResponsiveState;
