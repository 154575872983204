import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";
import LoadIcon from "../assets/img/surface1.svg";
import CleanIcon from "../assets/img/clean.svg";
import AnalyzeIcon from "../assets/img/Layer_x0020_1.svg";
import SampleVideo1 from "../assets/img/Intro-gif1.gif";
import SampleVideo2 from "../assets/img/Intro-gif2.gif";
import SampleVideo3 from "../assets/img/Intro-gif3.gif";

import "./Introduction.css";

const Introduction = () => {

  return (
    <Swiper
      modules={[Navigation]}
      navigation
      spaceBetween={50}
      slidesPerView={1}
      className="carousel-wrapper"
    >
      <SwiperSlide>
        <section className="carousel-slide">
          <div className="content first-section">
            <h1 className="heroText">Datalyze</h1>
            <div className="first-card card">
              <h5 className="card-title">
                Welcome to <br />
                Your Data Playground!
              </h5>
              <p className="card-text">
                Gamify your data journey with Datalyze—an intuitive platform
                that helps you turn raw data into meaningful insights,
                effortlessly.
              </p>
            </div>
          </div>
          <div className="video-container">
            <img src={SampleVideo1} alt="Datalyze video" className="video" />
          </div>
        </section>
      </SwiperSlide>
      <SwiperSlide>
        <section className="carousel-slide">
          <div className="content mid-section">
            <h1 className="heroText">
              What Can You Do with <br />
              Datalyze?
            </h1>
            <div className="second-card card">
              <h5 className="mid-card-title">Play . Learn . Analyze!</h5>
              <div className="features">
                <div className="feature-card">
                  <img
                    src={LoadIcon}
                    alt="Load Icon"
                    className="feature-icon"
                  />
                  <div className="feature-text">
                    <h6 className="mid-feature-title">Load</h6>
                    <p className="mid-feature-description">
                      Upload your data or play with preloaded datasets.
                    </p>
                  </div>
                </div>
                <div className="feature-card">
                  <img
                    src={CleanIcon}
                    alt="Clean Icon"
                    className="feature-icon"
                  />
                  <div className="feature-text">
                    <h6 className="mid-feature-title">Clean</h6>
                    <p className="mid-feature-description">
                      Fix messy data with guided steps and earn progress badges.
                    </p>
                  </div>
                </div>
                <div className="feature-card">
                  <img
                    src={AnalyzeIcon}
                    alt="Analyze Icon"
                    className="feature-icon"
                  />
                  <div className="feature-text">
                    <h6 className="mid-feature-title">Analyze</h6>
                    <p className="mid-feature-description">
                      Create stunning visualizations in seconds and unlock
                      insights!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="video-container">
            <img
              src={SampleVideo2}
              alt="Datalyze features video"
              className="video"
            />
          </div>
        </section>
      </SwiperSlide>
      <SwiperSlide>
        <section className="carousel-slide">
          <div className="content last-section">
            <h1 className="heroText">Ready to Dive In?</h1>
            <div className="third-card card">
              <h5 className="card-title">Your Data Adventure Awaits!</h5>
              <p className="card-text">
                Pick your first challenge and start turning data into
                insights—one fun step at a time!
              </p>
            </div>
          </div>
          <div className="video-container">
            <img
              src={SampleVideo3}
              alt="Datalyze introduction video"
              className="video"
            />
          </div>
        </section>
      </SwiperSlide>
    </Swiper>
  );
};

export default Introduction;
