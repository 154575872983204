import React, { useEffect } from 'react';

const MissingValueTreatment = ({
    missingValueList,
    allData,
    selectedMissingValue,
    setSelectedMissingValue,
    onSubmit,
    columnDefinitions
}) => {
    let problemDetails = columnDefinitions;

    let incompleteObjects = {
    };

    problemDetails?.forEach((item) => {
        incompleteObjects = Object.assign({}, incompleteObjects, {
            [item?.accessor]: 0
        })
    });

    missingValueList?.forEach(obj => {
        Object.keys(obj)?.forEach((key) => {
            if (!obj?.[key]) {
                incompleteObjects = Object.assign({}, incompleteObjects, {
                    [key]: incompleteObjects?.[key] + 1
                })
            }
        })
    });

    useEffect(() => {
        let finalObj = {};
        Object.keys(incompleteObjects)?.forEach((key) => {
            if (incompleteObjects?.[key] > 0) {
                finalObj = Object.assign({}, finalObj, {
                    [key]: "delete"
                })
            }
        });
        setSelectedMissingValue(finalObj);
    }, []);


    Object.keys(incompleteObjects)?.forEach((key) => {
        if (!incompleteObjects?.[key]) {
            delete incompleteObjects[key];
        }
    });

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#010C12",
                borderRadius: "6px 6px 16px 16px",
                borderTop: "1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08))",
                padding: "15px"
            }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                        <h3 style={{
                            color: "var(--Neutral-100, #FFF)",
                            fontFamily: "Plus Jakarta Sans",
                            fontSize: "16px",
                            fontWeight: 500
                        }}>Missing Data Field</h3>

                        <button
                            style={{
                                color: "#000",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "12px",
                                fontWeight: 700,
                                width: "104px",
                                height: "34px",
                                borderRadius: "12px",
                                background: "linear-gradient(91deg, #00FF85 7.38%, #00E5FF 96.85%)",
                                border: "none"
                            }}
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            Submit
                        </button>
                    </div>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        background: "var(--Glass-Fill, linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0.00) 100%))",
                        height: "41px",
                        alignItems: "center",
                        color: "#B8B8B8",
                        fontSize: "12px",
                        fontWeight: 500,

                    }}>



                        <p style={{ width: "20%", textAlign: "left", paddingLeft: "15px" }}>Column Name</p>
                        <p style={{ width: "20%", textAlign: "left" }}>Data Type</p>
                        <p style={{ width: "20%", textAlign: "left" }}>Missing Value Count</p>
                        <p style={{ width: "20%", textAlign: "left" }}>Missing Value %</p>
                        <p style={{ width: "20%", textAlign: "left" }}>Action</p>
                    </div>
                    {Object.keys(incompleteObjects)?.map((Column, i) => {
                        return incompleteObjects?.[Column] > 0 ? (
                            <div
                                key={Column} // Always provide a unique key for mapped elements
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    marginBottom: "15px",
                                    height: "59px",
                                    backgroundColor: i % 2 === 0 ? "#010C12 " : "rgba(42, 44, 48, 0.3)", // Alternate row style
                                    border: i % 2 === 0 ? "none" : "1px solid #363A3D",
                                    color: "var(--Neutral-100, #FFF)",
                                    fontSize: "14px",
                                    fontWeight: 500
                                }}
                            >
                                <p style={{ marginRight: "25px", width: "20%", textAlign: "left", paddingLeft: "15px", paddingTop: "10px" }}>{Column}</p>
                                <p style={{ marginRight: "25px", width: "20%", textAlign: "left", paddingTop: "10px" }}>{Column === "Card Category" || Column === "Customer Name" ? "Category" : "Numerical"}</p>
                                <p style={{ marginRight: "25px", width: "20%", textAlign: "center", paddingTop: "10px" }}>{incompleteObjects?.[Column]}</p>
                                <p style={{ marginRight: "25px", width: "20%", textAlign: "center", paddingTop: "10px" }}>{((incompleteObjects?.[Column] / allData?.length) * 100).toFixed(2)} %</p>
                                {Column === "Customer Name" || Column === "Card Category" ? <select
                                    style={{
                                        marginRight: "25px",
                                        width: "20%",
                                        textAlign: "left",
                                        background: "var(--Glass-Fill, linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0.00) 100%))",
                                        color: "var(--Neutral-100, #FFF)",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        height: "34px",
                                        padding: "5px",
                                        border: "1px solid #01FC91",
                                        borderRadius: "10px",
                                        paddingTop: "10px"
                                    }}
                                    className='filter-input'
                                    value={selectedMissingValue?.[Column] || "delete"}
                                    onChange={(e) => {
                                        setSelectedMissingValue(Object.assign({}, selectedMissingValue, {
                                            [Column]: e?.target?.value
                                        }))
                                    }}
                                >

                                    <option value="delete">Remove Row</option>
                                    <option value="mode">Mode Imputation</option>
                                </select> : <select
                                    style={{
                                        marginRight: "25px",
                                        width: "20%",
                                        textAlign: "left",
                                        background: "var(--Glass-Fill, linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0.00) 100%))",
                                        color: "var(--Neutral-100, #FFF)",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        height: "34px",
                                        padding: "5px",
                                        border: "1px solid #01FC91",
                                        borderRadius: "10px",
                                        paddingTop: "10px"
                                    }}
                                    className='filter-input'
                                    value={selectedMissingValue?.[Column] || "delete"}
                                    onChange={(e) => {
                                        setSelectedMissingValue(Object.assign({}, selectedMissingValue, {
                                            [Column]: e?.target?.value
                                        }))
                                    }}
                                >
                                    <option value="delete">Remove Row</option>
                                    <option value="mean">Mean Imputation</option>
                                    <option value="median">Median Imputation</option>
                                </select>}
                            </div>
                        ) :
                            null
                    })
                    }
                </div>
            </div >


        </>
    )
}

export default MissingValueTreatment