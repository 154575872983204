import React, { useState } from "react";
import DATALYZE from "../assets/img/DATALYZE.svg";
import MachineHack from "../assets/img/MH.svg";
import Email from "../assets/img/email.svg";
import Password from "../assets/img/password.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useLoginUserMutation, useSignupUserMutation } from "../store/services/userLogin";
import { setJwtToken } from "../store/slices";
import { useDispatch } from "react-redux";
import { Tabs, Tab, Box } from '@mui/material';

import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const [loginUser, { isLoading, error }] = useLoginUserMutation();
  const [signupUser, { isLoading: isLoadingSignup, }] = useSignupUserMutation();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState(0);
  // const API_URL =
  //   "https://murhsmozuf.execute-api.ap-south-1.amazonaws.com/dev/signin";
  const [apiError, setApiError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
      name: tabs === 0 ? Yup.string().required("Name is required") : Yup.string(),
    }),
    onSubmit:
      async (values) => {
        setApiError("");
        try {
          if (tabs === 0) {
            const result = await signupUser({ body: values });
            if (result?.data?.data?.token) {
              dispatch(setJwtToken({ token: result?.data?.data?.token }));
              navigate("/gamelist");
            } else {
              console.log(result, 'error?.data?.status', result?.error?.data?.message)
              setApiError(result?.error?.data?.message);
            }
          } else {
            const result = await loginUser({ body: values });
            if (result?.data?.data?.token) {
              dispatch(setJwtToken({ token: result?.data?.data?.token }));
              navigate("/gamelist");
            } else {
              setApiError("Invalid credentials or tenant ID");
            }
          }
        } catch (err) {
          console.error("Login failed:", error);
          setApiError("Invalid credentials or tenant ID");
        }
      },
  });

  // console.log(apiError, 'apiError')
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className="login-container">
      <div className="login-form">
        <div className="login-form-overlay"></div>
        <img src={DATALYZE} alt="Datalyze Logo" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <p className="by">by</p>{" "}
          <img src={MachineHack} className="title" alt="MachineHack Logo" />
        </div>
        <p className="description">
          A Gamified learning platform for self-paced data analytics mastery
        </p>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabs}
            onChange={(event, newValue) => {
              if (!isLoading && !isLoadingSignup) {
                setTabs(newValue)
              }
            }}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{
                color: tabs == 0 ? "#01FF85 !important" : '#fff',
              }}
              label="Sign up" {...a11yProps(0)} />
            <Tab
              sx={{
                color: tabs == 1 ? "#01FF85 !important" : '#fff',
              }}
              label="Sign in" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <form onSubmit={formik.handleSubmit} style={{ minHeight: "300px" }}>

          <div className="input-container" style={{ marginBottom: "25px" }}>
            <input
              className="email"
              type="text"
              placeholder="Enter your email"
              {...formik.getFieldProps("email")}
            />
            <img src={Email} className="email-icon" alt="Email Icon" />
            {formik.touched.email && formik.errors.email ? (
              <p className="error-message">{formik.errors.email}</p>
            ) : null}
          </div>
          <div className="input-container" style={{ marginBottom: "25px" }}>
            <input
              className="email"
              type="password"
              placeholder="Password"
              {...formik.getFieldProps("password")}
            />
            <img src={Password} className="email-icon" alt="Password Icon" />
            {formik.touched.password && formik.errors.password ? (
              <p className="error-message">{formik.errors.password}</p>
            ) : null}
          </div>

          {tabs === 0 && (
            <div className="input-container" style={{ marginBottom: "25px" }}>
              <input
                className="name"
                type="text"
                placeholder="Enter your name"
                {...formik.getFieldProps("name")}
                style={{ marginBottom: "20px" }}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className="error-message">{formik.errors.name}</p>
              ) : null}
            </div>
          )}

          {isLoading ? <p>Loading</p> : <button className="submit-btn" type="submit">
            Continue
          </button>}

          {apiError && <p className="error-message">{apiError}</p>}
        </form>
      </div>
      <div className="login-image-contianer"></div>
    </div>
  );
};

export default Login;