import React, { useRef, useEffect } from 'react'
import { chart as rawChart } from '@rawgraphs/rawgraphs-core'
import useDebounce from '../hooks/useDebounce'
import WarningMessage from '../WarningMessage'
import { onChartRendered } from '../../gaEvents'
import useResponsiveState from '../../Hooks/useResponsiveState';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ExpandIcon from '../../mui/ExpandIcon';
import CollapseIcon from '../../mui/CollapseIcon';
import { motion } from 'framer-motion';

const ChartPreview = ({
  chart,
  dataset: data,
  dataTypes,
  mapping,
  visualOptions,
  error,
  setError,
  setRawViz,
  mappedData,
  onExpandClick,
  chatExpanded,
  expandStatus,
}) => {
  const domRef = useRef(null)

  const vizOptionsDebounced = useDebounce(visualOptions, 200);
  const { dimensions } = useResponsiveState(chatExpanded,expandStatus);
  const { width, height } = dimensions;

  const handleExpandClick = () => {
    onExpandClick(!expandStatus);
  };

  useEffect(() => {
    setError(null);

    // Control required variables
    const currentlyMapped = [];
    for (let variable in mapping) {
      if (mapping[variable].ids && mapping[variable].ids.length > 0) {
        currentlyMapped.push(variable);
      }
    }

    const requiredVariables = chart.dimensions.filter(
      (d) => d.required && currentlyMapped.indexOf(d.id) === -1
    );

    if (requiredVariables.length > 0) {
      let errorMessage = (
        <span>
          Required chart variables: you need to map{' '}
          {requiredVariables
            .map((d, i) => (
              <span key={i} className="font-weight-bold">
                {d.name}
              </span>
            ))
            .reduce((prev, curr) => [prev, ' and ', curr])}
        </span>
      );
      setError({ variant: 'secondary', message: errorMessage });
      setRawViz(null);
      clearDom();
      return;
    }

    // Control multiple required variables
    const multivaluesVariables = chart.dimensions.filter(
      (d) =>
        d.multiple &&
        d.required &&
        d.minValues &&
        mapping[d.id].ids.length < d.minValues
    );

    if (multivaluesVariables.length > 0) {
      let errorMessage = (
        <span>
          Please map{' '}
          {multivaluesVariables
            .map((d) => (
              <>
                at least <span className="font-weight-bold">{d.minValues}</span>{' '}
                dimensions on <span className="font-weight-bold">{d.name}</span>
              </>
            ))
            .reduce((prev, curr) => [prev, ' and ', curr])}
          .
        </span>
      );
      setError({ variant: 'secondary', message: errorMessage });
      setRawViz(null);
      clearDom();
      return;
    }

    // Control data-types mismatches
    for (let variable in mapping) {
      if (
        mapping[variable].ids &&
        mapping[variable].ids.length > 0 &&
        !mapping[variable].isValid
      ) {
        const variableObj = chart.dimensions.find((d) => d.id === variable);
        const errorMessage = `Data-type mismatch: you can’t map ${mapping[variable].mappedType}s on ${variableObj.name}.`;
        setError({ variant: 'danger', message: errorMessage });
        setRawViz(null);
        clearDom();
        return;
      }
    }

    // Check if mappedData is null or empty
    // if (!mappedData || Object.keys(mappedData).length === 0) {
    //   setError({ variant: 'secondary', message: 'No data to render the chart.' });
    //   setRawViz(null);
    //   clearDom();
    //   return;
    // }

    // Render the chart
    try {
      const viz = rawChart({ ...chart, type: "svg" }, {
        data,
        mapping: mapping,
        dataTypes,
        visualOptions: {
          vizOptionsDebounced,
          width,
          height
        },
      },
      )

      try {
        const rawViz = viz.renderToDOM(domRef.current, mappedData)
        setRawViz(rawViz)
        setError(null)
        onChartRendered(chart.metadata)
      } catch (e) {
        console.log("chart error", e)
        setError({ variant: 'danger', message: 'Chart error. ' + e.message })
        setRawViz(null)
      }
    } catch (e) {
      while (domRef.current.firstChild) {
        domRef.current.removeChild(domRef.current.firstChild)
      }
      console.log({ e })
      setError({ variant: 'danger', message: 'Chart error. ' + e.message })
      setRawViz(null)
    }

  }, [setError, vizOptionsDebounced, setRawViz, mappedData, chart, mapping , width, height])

  const clearDom = () => {
    while (domRef.current.firstChild) {
      domRef.current.removeChild(domRef.current.firstChild);
    }
  };
  return (
    <motion.div
    initial={{ opacity: 0, y: -20 }} // Start slightly below
    animate={{
      opacity: 1,  // Animate to full opacity
      y: 0,        // Animate to its final position
    }}
    exit={{
      opacity: 0,  // Fade out when exiting
      y: 20,       // Slightly move down when exiting
    }}
    transition={{
      type: "spring",
      stiffness: 300, // Spring stiffness, controls the speed of the animation
      damping: 25,    // Damping controls the bounce effect (lower = more bounce)
      duration: 0.5,  // Duration of the transition
    }}
  >
    <div style={{ marginTop: "20px", height: "auto", }}>
    <div style={{
      display:"flex",
      justifyContent:"space-between",
      alignItems: "center",
      marginBottom: "20px",
    }}>
      <h3 style={{
        color: "#fff",
        fontSize: "24px",
        fontWeight: 600,
      }}>Output</h3>

      <Tooltip title={expandStatus ? "Expand Graph" : "Collapse Graph"} arrow>
      <IconButton onClick={handleExpandClick}> {expandStatus ?  <CollapseIcon /> : <ExpandIcon /> }</IconButton>
      </Tooltip>

    </div>
      <div
        className={['overflow-auto', 'position-sticky'].join(' ')}
        style={{
          display: 'flex',
          justifyContent:"center",
          alignItems: 'center',
          top: 'calc(15px + var(--header-height))', borderRadius: "10px",
          color: "green",
          width: "fit-content",
          height: "100%"
          // height: "200px",
          // background: "red",
          // overflowY: "scroll"
        }}
      >
        {error && (
          <WarningMessage variant={error.variant} message={error.message} />
        )}
        <div
          ref={domRef}></div>
      </div>
    </div>
    </motion.div>
  )
}

export default React.memo(ChartPreview)
